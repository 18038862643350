import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { SetorType } from '../../../types/SetorType';

const LIST_SETORES = gql`
  query ListarSetores($sort: JsonValue) {
    ListarSetores(sort: $sort) {
      values {
        id
        nome
      }
    }
  }
`;

const GET_ATIVO = gql`
  query BuscarAtivo($id: String!) {
    BuscarAtivo(id: $id) {
      id
      nome
      setor {
        id
      }
      cotacao
    }
  }
`;

const UPDATE_ATIVO = gql`
  mutation AlterarAtivo($id: String!, $input: AlterarAtivoInput!) {
    AlterarAtivo(id: $id, input: $input) {
      code
      message
      stack
    }
  }
`;

const AlterarAtivo = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [setores, setSetores] = useState<SetorType[] | null>([]);
  const params = useParams<{ id: string }>();

  const { loading: loadingSetores } = useQuery(LIST_SETORES, {
    variables: {
      sort: {
        nome: 1,
      },
    },
    onCompleted: (res) => {
      setSetores(res.ListarSetores.values);
    },
    onError: (err) => {
      setIsDisabled(true);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const { loading } = useQuery(GET_ATIVO, {
    variables: {
      id: params?.id,
    },
    onCompleted: (res) => {
      formik.setValues({
        ativo: res.BuscarAtivo.id,
        nome: res.BuscarAtivo.nome,
        setor: res.BuscarAtivo?.setor?.id || '',
        cotacao: res.BuscarAtivo.cotacao.toFixed(2),
      });
    },
    onError: (err) => {
      setIsDisabled(true);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [updateAtivo, { loading: updating }] = useMutation(UPDATE_ATIVO, {
    onCompleted: (res) => {
      setAlert({ message: res.AlterarAtivo.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      ativo: yup.string().required(),
      nome: yup.string().required(),
      cotacao: yup.number().positive().required(),
      setor: yup.string(),
    }),
    initialValues: {
      ativo: '',
      nome: '',
      cotacao: '',
      setor: '',
    },
    onSubmit: values => {
      setAlert(null);
      updateAtivo({
        variables: {
          id: params?.id,
          input: {
            nome: values.nome,
            cotacao: Number(values.cotacao),
            setor: values.setor || null,
          },
        },
      });
      },
  });

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Alterar Ativo</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loading || loadingSetores || updating}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='ativo'>
                          <Form.Label>Ativo:</Form.Label>
                          <Form.Control
                            type='text'
                            name='ativo'
                            placeholder='ativo'
                            value={formik.values.ativo}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='3'>
                        <Form.Group className='mb-4' controlId='nome'>
                          <Form.Label>Nome:</Form.Label>
                          <Form.Control
                            type='text'
                            name='nome'
                            placeholder='nome'
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.nome && !formik.errors.nome}
                            isInvalid={formik.touched.nome && formik.errors.nome !== undefined}
                            autoFocus
                            maxLength={36}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='cotacao'>
                          <Form.Label>Cotação:</Form.Label>
                          <Form.Control
                            type='text'
                            name='cotacao'
                            placeholder='0.00'
                            value={formik.values.cotacao}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.cotacao && !formik.errors.cotacao}
                            isInvalid={formik.touched.cotacao && formik.errors.cotacao !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='3'>
                        <Form.Group controlId='setor'>
                          <Form.Label>Setor:</Form.Label>
                          <Form.Select
                            name='setor'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.values.setor !== ''}
                            value={formik.values.setor}
                          >
                            <option value=''></option>
                            {setores?.map(item => {
                              return (
                                <option key={item.id} value={item.id}>{item.nome}</option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loading || loadingSetores || updating}>Alterar</Button>
            <Button variant='outline-primary' disabled>Limpar</Button>
          </div>
        </Form>
        <Spinner show={loading || loadingSetores || updating} />
      </Container>
    </Layout>
  );
}

export default AlterarAtivo;
