import { Spinner as ReactSpinner } from 'react-bootstrap';

import styles from './index.module.scss'

type PropsType = {
  show: boolean;
};

const Spinner = ({ show }: PropsType) => {
  if (show) {
    return (
      <ReactSpinner className={styles.loading} animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </ReactSpinner>
    );
  };
  return null;
};

export default Spinner;
