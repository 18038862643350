import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { SetorType } from '../../../types/SetorType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import ModalYesNo from '../../../components/ModalYesNo';
import { AlertType } from '../../../types/AlertType';
import ContextMenu from '../../../components/ContextMenu';

library.add(fas);

const GET_SETORES = gql`
  query BuscarSetores($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarSetores(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        nome
        icone
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const DELETE_SETOR = gql`
  mutation ExcluirSetor($id: String!) {
    ExcluirSetor(id: $id) {
      code
      message
      stack
    }
  }
`;

const PAGE_SIZE = 10;

const FILTER = {};

const SORT = {
  nome: 1,
};

const ListarSetor = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [setorId, setSetorId] = useState<string | null>(null);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [showContext, setShowContext] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowContext(false);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
    setShowContext(false);
  });

  const { data, loading, refetch } = useQuery(GET_SETORES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [deleteSetor, { loading: deleting }] = useMutation(DELETE_SETOR, {
    onCompleted: (res) => {
      refetch();

      setSetorId(null);
      setAlert({ message: res.ExcluirSetor.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const setor: {
    items: SetorType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarSetores.values || [],
    page: data?.BuscarSetores.aggregate.page || 1,
    count: data?.BuscarSetores.aggregate.count || 0,
    total: data?.BuscarSetores.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
  };

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      ...FILTER,
      nome: {
        $regex: `.*${search}.*`,
        $options: 'i',
      },
    };
    refetch({
      filter,
    });
  };

  const handleDelete = () => {
    setAlert(null);
    setShowDelete(false);
    deleteSetor({
      variables: {
        id: setorId,
      }
    });
  };

  const handleContext = (e: any, id: string) => {
    e.preventDefault();
    setSetorId(id);
    setPosition({ x: e.pageX, y: e.pageY });
    setShowContext(true);
  };

  const actionUpdate = () => {
    setAlert(null);
    setShowContext(false);
    navigate(`/setor/alterar/${setorId}`);
  };

  const actionDelete = () => {
    setAlert(null);
    setShowContext(false);
    setShowDelete(true);
  };

  const menuOptions = [
    {name: 'Alterar', disabled: false, action: actionUpdate},
    {name: 'Excluir', disabled: false, action: actionDelete},
  ];

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th>Nome</th>
              <th>Ícone</th>
              <th>Imagem</th>
            </tr>
          </thead>
          <tbody>
            {setor.items.map(item => {
              return (
                <tr key={item.id} className={styles.row} onContextMenu={(event) => handleContext(event, item.id)}>
                  <td className={styles.textColumn}>{item.nome}</td>
                  <td className={styles.textColumn}>{item.icone}</td>
                  <td className={styles.iconColumn}><FontAwesomeIcon icon={item.icone as IconProp} size='lg' /></td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ContextMenu
          options={menuOptions}
          show={showContext && !loading && !deleting}
          position={position}
        />
        <Pagination
          total={setor.total}
          page={setor.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalYesNo
          title='Confirma exclusão do registro?'
          show={showDelete}
          onNo={setShowDelete}
          onYes={handleDelete}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading || deleting} />
      </Container>
   </Layout>
  )
};

export default ListarSetor;
