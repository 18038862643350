import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Container, Table, Alert } from 'react-bootstrap';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { CorretagemType } from '../../../types/CorretagemType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import { AlertType } from '../../../types/AlertType';
import { formatNumber } from '../../../helpers/Util';
import ContextMenu from '../../../components/ContextMenu';

const GET_CORRETAGENS = gql`
  query BuscarCorretagens($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarCorretagens(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        tipo
        data
        ativo {
          id
        }
        preco
        qtde
        liquidacao
        emolumentos
        taxa
        iss
        custo
        imposto
        status
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const PAGE_SIZE = 10;

const FILTER = {};

const SORT = {
  data: -1,
  id: -1,
};

const ListarCorretagem = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [corretagemId, setCorretagemId] = useState<string | null>(null);
  const [showContext, setShowContext] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowContext(false);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
    setShowContext(false);
  });

  const { data, loading, refetch } = useQuery(GET_CORRETAGENS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const corretagem: {
    items: CorretagemType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarCorretagens.values || [],
    page: data?.BuscarCorretagens.aggregate.page || 1,
    count: data?.BuscarCorretagens.aggregate.count || 0,
    total: data?.BuscarCorretagens.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
  };

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      ...FILTER,
      'ativo.id': {
        $regex: `.*${search}.*`,
        $options: 'i',
      },
    };
    refetch({
      filter,
      page: 1,
    });
  };

  const handleContext = (e: any, id: string, status: string) => {
    e.preventDefault();
    setAlert(null);
    setCorretagemId(id);
    setPosition({ x: e.pageX, y: e.pageY });
    setShowContext(true && status !== 'INATIVO');
  };
  
  const actionUpdate = () => {
    setShowContext(false);
    navigate(`/corretagem/alterar/${corretagemId}`);
  };

  const menuOptions = [
    {name: 'Alterar', disabled: false, action: actionUpdate},
  ];

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th>Tipo</th>
              <th>Data</th>
              <th>Ativo</th>
              <th>Qtde</th>
              <th>Preço</th>
              <th>Liquid.</th>
              <th>Emol.</th>
              <th>Taxa</th>
              <th>ISS</th>
              <th>Custo</th>
              <th>Imposto</th>
            </tr>
          </thead>
          <tbody>
            {corretagem.items.map(item => {
              return (
                <tr key={item.id} className={styles.row} onContextMenu={(event) => handleContext(event, item.id, item.status)}>
                  <td className={styles.ativoColumn}>{item.tipo}</td>
                  <td className={styles.textColumn}>{item.data}</td>
                  <td className={styles.textColumn}>{item.ativo.id}</td>
                  <td className={styles.textColumn}>{formatNumber(item.qtde, 0)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.preco, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.liquidacao, 2, false)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.emolumentos, 2, false)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.taxa, 2, false)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.iss, 2, false)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.custo, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.imposto, 2, false)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ContextMenu
          options={menuOptions}
          show={showContext && !loading}
          position={position}
        />
        <Pagination
          total={corretagem.total}
          page={corretagem.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading} />
      </Container>
   </Layout>
  )
};

export default ListarCorretagem;
