import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment-timezone';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { REGEX_ATIVO, REGEX_DATA } from '../../../helpers/Validation';

const GET_POSICAO = gql`
  query BuscarPosicao($id: String!) {
    BuscarPosicao(id: $id) {
      id
      data
      tipo
      ativo {
        id
        cotacao
      }
      preco_entrada
      saida_parcial {
        qtde
        preco
      }
      saida_final {
        qtde
        preco
      }
      status
    }
  }
`;

const TRANSPORT_POSICAO = gql`
  mutation TransportarPosicao($id: String!, $input: ResultadoInput!) {
    TransportarPosicao(id: $id, input: $input) {
      code
      message
      stack
    }
  }
`;

const ZerarPosicao = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams<{ id: string }>();

  const { loading } = useQuery(GET_POSICAO, {
    variables: {
      id: params?.id,
    },
    onCompleted: (res) => {
      formik.setValues({
        data_entrada: res.BuscarPosicao.data,
        tipo: res.BuscarPosicao.tipo,
        ativo: res.BuscarPosicao.ativo.id,
        qtde: res.BuscarPosicao.saida_parcial.qtde + res.BuscarPosicao.saida_final.qtde,
        data_saida: moment().format('YYYY-MM-DD'),
        preco_compra: (res.BuscarPosicao.tipo === 'COMPRA' ? res.BuscarPosicao.preco_entrada.toFixed(2) : res.BuscarPosicao.ativo.cotacao.toFixed(2)),
        preco_venda: (res.BuscarPosicao.tipo === 'COMPRA' ? res.BuscarPosicao.ativo.cotacao.toFixed(2) : res.BuscarPosicao.preco_entrada.toFixed(2)),
      });
    },
    onError: (err) => {
      setIsDisabled(true);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [transportPosicao, { loading: transporting }] = useMutation(TRANSPORT_POSICAO, {
    onCompleted: (res) => {
      setIsDisabled(true);
      setAlert({ message: res.TransportarPosicao.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      data_entrada: yup.string().required().matches(REGEX_DATA),
      tipo: yup.string().required(),
      ativo: yup.string().required().matches(REGEX_ATIVO),
      preco_compra: yup.number().positive().required(),
      qtde: yup.number().positive().required(),
      data_saida: yup.string().required().matches(REGEX_DATA),
      preco_venda: yup.number().positive().required(),
    }),
    initialValues: {
      data_entrada: '',
      tipo: 'COMPRA',
      ativo: '',
      preco_compra: '',
      qtde: '',
      data_saida: '',
      preco_venda: '',
    },
    onSubmit: values => {
      setAlert(null);
      transportPosicao({
        variables: {
          id: params?.id,
          input: {
            data_entrada: values.data_entrada,
            tipo: values.tipo,
            ativo: values.ativo,
            preco_compra: Number(values.preco_compra),
            qtde: Number(values.qtde),
            data_saida: values.data_saida,
            preco_venda: Number(values.preco_venda),
            status: 'CANCELADO',
          },
        },
      });
    },
  });

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Transportar Posição</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loading || transporting}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='data_entrada'>
                          <Form.Label>Data Entrada:</Form.Label>
                          <Form.Control
                            type='text'
                            name='data_entrada'
                            placeholder='aaaa-mm-dd'
                            value={formik.values.data_entrada}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='data_saida'>
                          <Form.Label>Data Saída:</Form.Label>
                          <Form.Control
                            type='text'
                            name='data_saida'
                            placeholder='aaaa-mm-dd'
                            value={formik.values.data_saida}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.data_saida && !formik.errors.data_saida}
                            isInvalid={formik.touched.data_saida && formik.errors.data_saida !== undefined}
                            maxLength={10}
                            autoFocus
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='ativo'>
                          <Form.Label>Ativo:</Form.Label>
                          <Form.Control
                            type='text'
                            name='ativo'
                            placeholder='ativo'
                            value={formik.values.ativo}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='qtde'>
                          <Form.Label>Qtde:</Form.Label>
                          <Form.Control
                            type='text'
                            name='qtde'
                            placeholder='0'
                            value={formik.values.qtde}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                    <Col md='2'>
                        <Form.Group className='mb-4' controlId='preco_compra'>
                          <Form.Label>Preço Compra:</Form.Label>
                          <Form.Control
                            type='text'
                            name='preco_compra'
                            placeholder='0.00'
                            value={formik.values.preco_compra}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.preco_compra && !formik.errors.preco_compra}
                            isInvalid={formik.touched.preco_compra && formik.errors.preco_compra !== undefined}
                            maxLength={11}
                            disabled={formik.values.tipo === 'COMPRA'}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='preco_venda'>
                          <Form.Label>Preço Venda:</Form.Label>
                          <Form.Control
                            type='text'
                            name='preco_venda'
                            placeholder='0.00'
                            value={formik.values.preco_venda}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.preco_venda && !formik.errors.preco_venda}
                            isInvalid={formik.touched.preco_venda && formik.errors.preco_venda !== undefined}
                            maxLength={11}
                            disabled={formik.values.tipo === 'VENDA'}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loading || transporting}>Zerar</Button>
            <Button variant='outline-primary' disabled>Limpar</Button>
          </div>
        </Form>
        <Spinner show={loading || transporting} />
      </Container>
    </Layout>
  );
}

export default ZerarPosicao;
