import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';

const GET_SETOR = gql`
  query BuscarSetor($id: String!) {
    BuscarSetor(id: $id) {
      id
      nome
      icone
    }
  }
`;

const UPDATE_SETOR = gql`
  mutation AlterarSetor($id: String!, $input: SetorInput!) {
    AlterarSetor(id: $id, input: $input) {
      code
      message
      stack
    }
  }
`;

const AlterarSetor = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams<{ id: string }>();

  const { loading } = useQuery(GET_SETOR, {
    variables: {
      id: params?.id,
    },
    onCompleted: (res) => {
      formik.setValues({
        nome: res.BuscarSetor.nome,
        icone: res.BuscarSetor.icone
      });
    },
    onError: (err) => {
      setIsDisabled(true);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [updateSetor, { loading: updating }] = useMutation(UPDATE_SETOR, {
    onCompleted: (res) => {
      setAlert({ message: res.AlterarSetor.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      nome: yup.string().required(),
      icone: yup.string().required(),
    }),
    initialValues: {
      nome: '',
      icone: '',
    },
    onSubmit: values => {
      setAlert(null);
      updateSetor({
        variables: {
          id: params?.id,
          input: {
            nome: values.nome,
            icone: values.icone,
          },
        },
      });
    },
  });

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Alterar Setor</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loading || updating}>
                    <Row>
                      <Col md={4}>
                        <Form.Group className='mb-4' controlId='nome'>
                          <Form.Label>Nome:</Form.Label>
                          <Form.Control
                            type='text'
                            name='nome'
                            placeholder='nome'
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.nome && !formik.errors.nome}
                            isInvalid={formik.touched.nome && formik.errors.nome !== undefined}
                            autoFocus
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Form.Group controlId='icone'>
                          <Form.Label>Ícone:</Form.Label>
                          <Form.Control
                            type='text'
                            name='icone'
                            placeholder='ícone'
                            value={formik.values.icone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.icone && !formik.errors.icone}
                            isInvalid={formik.touched.icone && formik.errors.icone !== undefined}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loading || updating}>Alterar</Button>
            <Button variant='outline-primary' disabled>Limpar</Button>
          </div>
        </Form>
        <Spinner show={loading || updating} />
      </Container>
    </Layout>
  );
}

export default AlterarSetor;
