import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { PosicaoType } from '../../../types/PosicaoType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import ModalYesNo from '../../../components/ModalYesNo';
import { AlertType } from '../../../types/AlertType';
import { formatNumber } from '../../../helpers/Util';

library.add(fas);

const GET_POSICOES = gql`
  query BuscarPosicoes($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarPosicoes(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        data
        tipo
        ativo {
          id
          cotacao
          setor {
            nome
            icone
          }
        }
        preco_entrada
        stop_loss
        saida_parcial {
          qtde
          preco
          ganho_projetado
          perda_projetada
        }
        saida_final {
          qtde
          preco
          ganho_projetado
          perda_projetada
        }
        status
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const DELETE_POSICAO = gql`
  mutation ExcluirPosicao($id: String!) {
    ExcluirPosicao(id: $id) {
      code
      message
      stack
    }
  }
`;

const PAGE_SIZE = 3;

const FILTER = {
  status: {
    $in: ['ATIVO', 'PARCIAL'],
  },
};

const SORT = {
  data: -1,
  id: -1,
};

const ListarPosicao = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [posicaoId, setPosicaoId] = useState<string | null>(null);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const navigate = useNavigate();

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
  });

  const { data, loading, refetch } = useQuery(GET_POSICOES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [deletePosicao, { loading: deleting }] = useMutation(DELETE_POSICAO, {
    onCompleted: (res) => {
      refetch();
      setPosicaoId(null);
      setAlert({ message: res.ExcluirPosicao.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const posicao: {
    items: PosicaoType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarPosicoes.values || [],
    page: data?.BuscarPosicoes.aggregate.page || 1,
    count: data?.BuscarPosicoes.aggregate.count || 0,
    total: data?.BuscarPosicoes.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
  };

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      ...FILTER,
      'ativo.id': {
        $regex: `.*${search}.*`,
        $options: 'i',
      },
    };
    refetch({
      filter,
      page: 1,
    });
  };

  const handleDelete = () => {
    setAlert(null);
    setShowDelete(false);
    deletePosicao({
      variables: {
        id: posicaoId,
      }
    });
  };

  const calcResultado = (item: PosicaoType) => {
    let valor = 0.00;
    let perc = 0.00;
  
    if (item.tipo === 'COMPRA') {
        perc = ((item.ativo.cotacao / item.preco_entrada) - 1) * 100.00;
    } else {
        perc = ((item.preco_entrada / item.ativo.cotacao) - 1) * 100.00;
    }
  
    const total = item.saida_final.qtde + (item.status === 'PARCIAL' ? 0 : item.saida_parcial.qtde);
    if (item.tipo === 'COMPRA') {
      valor = (item.ativo.cotacao - item.preco_entrada) * total;
    } else {
      valor = (item.preco_entrada - item.ativo.cotacao) * total;
    }
  
    const arrow = (perc < 0 ? 'arrow-down' : 'arrow-up');
    const text = (perc < 0 ? 'text-danger' : 'text-success');
  
    return {
      perc: perc,
      valor: valor,
      arrow,
      text,
    };
  };
  
  const calcPerda = (item: PosicaoType) => {
    const valor = item.saida_final.perda_projetada + (item.status === 'ATIVO' ? item.saida_parcial.perda_projetada : 0.00);
    return {
      text: valor >= 0 ? 'text-success' : 'text-danger',
      valor,
    };
  };

  const renderCard = (item: PosicaoType) => {
    const resultado = calcResultado(item);

    const perda = calcPerda(item);

    return (
      <Card className='shadow'>
        <Card.Header className={styles.cardHeader}>
          <Row>
            <Col md='1' className='text-center'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>{item.ativo?.setor?.nome}</Tooltip>}
              >
                <FontAwesomeIcon icon={item.ativo?.setor?.icone as IconProp} className={styles.icon} />
              </OverlayTrigger>
            </Col>
            <Col md='3' className='text-start'>
              {item.ativo.id}
            </Col>
            <Col md='4' className='text-center'>
              {item.data}
            </Col>
            <Col md='4' className='text-end'>
              {(item.tipo === 'COMPRA' ? 'Compra' : 'Venda')}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className={styles.row}>
            <Col md='6'>
              <div>Preço Atual</div>
              <div>{formatNumber(item.ativo.cotacao, 2)}</div>
            </Col>
            <Col md='6' className={`${resultado.text}`}>
              <div>Resultado</div>
              <div>
                <span><FontAwesomeIcon icon={`${resultado.arrow}` as IconProp} /></span>
                <span>&nbsp;</span>
                <span>{formatNumber(Math.abs(resultado.valor), 0)}</span>
                <span>&nbsp;</span>
                <span>{`(${Math.abs(resultado.perc).toFixed(1)}%)`}</span>
              </div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md='4'>
              <div>Preço Entrada</div>
              <div>{formatNumber(item.preco_entrada, 2)}</div>
            </Col>
            <Col md='4'>
              <div>Stop Loss</div>
              <div>{formatNumber(item.stop_loss, 2)}</div>
            </Col>
            <Col md='4' className={`${perda.text}`}>
              <div>Perda</div>
              <div>{formatNumber(Math.abs(perda.valor), 0)}</div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md='4'>
              <div>Qtde Parcial</div>
              <div>{item.status === 'ATIVO' ? item.saida_parcial.qtde : '-'}</div>
            </Col>
            <Col md='4'>
              <div>Saída Parcial</div>
              <div>{item.status === 'ATIVO' ? formatNumber(item.saida_parcial.preco, 2) : '-'}</div>
            </Col>
            <Col md='4' className='text-success'>
              <div>Ganho</div>
              <div>{item.status === 'ATIVO' ? formatNumber(Math.abs(item.saida_parcial.ganho_projetado), 0) : '-'}</div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md='4'>
              <div>Qtde Final</div>
              <div>{formatNumber(item.saida_final.qtde, 0)}</div>
            </Col>
            <Col md='4'>
              <div>Saída Final</div>
              <div>{formatNumber(item.saida_final.preco, 2)}</div>
            </Col>
            <Col md='4' className='text-success'>
              <div>Ganho</div>
              <div>{formatNumber(Math.abs(item.saida_final.ganho_projetado), 0)}</div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row className='text-center'>
            <Col md='3'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Alterar</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='pencil'
                  size='lg'
                  className={deleting ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={() => navigate(`/posicao/alterar/${item.id}`)}
                />
              </OverlayTrigger>
            </Col>
            <Col md='3'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Excluir</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='trash-can'
                  size='lg'
                  className={(item.status === 'PARCIAL' || deleting) ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={() => {
                    setPosicaoId(item.id);
                    setShowDelete(true);
                  }}
                />
              </OverlayTrigger>
            </Col>
            <Col md='3'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Zerar</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='times-circle'
                  size='lg'
                  className={(item.status === 'PARCIAL' || deleting) ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={() => navigate(`/posicao/zerar/${item.id}`)}
                />
              </OverlayTrigger>
            </Col>
            <Col md='3'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>{item.status === 'ATIVO' ? 'Resultado Parcial' : 'Resultado Final'}</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='share-square'
                  size='lg'
                  className={deleting ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={() => navigate(`/posicao/transportar/${item.id}`)}
                />
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Row>
          <Col md='12'>
            <Card>
              <Card.Header className={styles.header}>Carteira de Ativos</Card.Header>
              <Card.Body>
                <Row>
                  {posicao.items.map(item => {
                    return (
                      <Col md='4' key={item.id}>
                        {renderCard(item)}
                      </Col>
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Pagination
          total={posicao.total}
          page={posicao.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalYesNo
          title='Confirma exclusão do registro?'
          show={showDelete}
          onNo={setShowDelete}
          onYes={handleDelete}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading || deleting} />
      </Container>
   </Layout>
  );
};

export default ListarPosicao;
