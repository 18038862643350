import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const Home = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['tradecontrol'])
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies?.tradecontrol) {
      navigate('/posicao/listar');
    };
    navigate('/login');
  });

  return (
    <></>
  );
};

export default Home;
