import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Table, Alert } from 'react-bootstrap';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { PatrimonioType } from '../../../types/PatrimonioType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import ModalYesNo from '../../../components/ModalYesNo';
import { AlertType } from '../../../types/AlertType';
import { formatNumber } from '../../../helpers/Util';
import ContextMenu from '../../../components/ContextMenu';

const GET_PATRIMONIOS = gql`
  query BuscarPatrimonios($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarPatrimonios(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        data
        descricao
        tipo
        valor
        saldo
        resultado
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const DELETE_PATRIMONIO = gql`
  mutation ExcluirPatrimonio($id: String!) {
    ExcluirPatrimonio(id: $id) {
      code
      message
      stack
    }
  }
`;

const PAGE_SIZE = 10;

const FILTER = {
  $regex: `.*.*`,
  $options: 'i',
};

const SORT = {
  data: -1,
  id: -1,
};

const ListarPatrimonio = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [patrimonioId, setPatrimonioId] = useState<string | null>(null);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [showContext, setShowContext] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowContext(false);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
    setShowContext(false);
  });

  const { data, loading, refetch } = useQuery(GET_PATRIMONIOS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [deletePatrimonio, { loading: deleting }] = useMutation(DELETE_PATRIMONIO, {
    onCompleted: (res) => {
      refetch();
      setPatrimonioId(null);
      setAlert({ message: res.ExcluirPatrimonio.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const patrimonio: {
    items: PatrimonioType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarPatrimonios.values || [],
    page: data?.BuscarPatrimonios.aggregate.page || 1,
    count: data?.BuscarPatrimonios.aggregate.count || 0,
    total: data?.BuscarPatrimonios.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
  };

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      $regex: `.*${search}.*`,
      $options: 'i',
    };
    refetch({
      filter,
      page: 1,
    });
  };

  const handleDelete = () => {
    setAlert(null);
    setShowDelete(false);
    deletePatrimonio({
      variables: {
        id: patrimonioId,
      }
    });
  };

  const handleContext = (e: any, id: string, resultado: string) => {
    e.preventDefault();
    setAlert(null);
    setPatrimonioId(id);
    setPosition({ x: e.pageX, y: e.pageY });
    setShowContext(true && resultado === null);
  };

  const actionUpdate = () => {
    setShowContext(false);
    navigate(`/patrimonio/alterar/${patrimonioId}`);
  };

  const actionDelete = () => {
    setShowContext(false);
    setShowDelete(true);
  };

  const menuOptions = [
    {name: 'Alterar', disabled: false, action: actionUpdate},
    {name: 'Excluir', disabled: false, action: actionDelete},
  ];
  
  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
              <th>Valor</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
            {patrimonio.items.map(item => {
              return (
                <tr key={item.id} className={styles.row} onContextMenu={(event) => handleContext(event, item.id, item.resultado)}>
                  <td className={styles.textColumn}>{item.data}</td>
                  <td className={styles.textColumn}>{item.descricao}</td>
                  <td className={styles.textColumn}>{formatNumber(item.valor * (item.tipo === 'D'? -1 : 1), 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.saldo, 2)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ContextMenu
          options={menuOptions}
          show={showContext && !loading && !deleting}
          position={position}
        />
        <Pagination
          total={patrimonio.total}
          page={patrimonio.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalYesNo
          title='Confirma exclusão do registro?'
          show={showDelete}
          onNo={setShowDelete}
          onYes={handleDelete}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading || deleting} />
      </Container>
   </Layout>
  )
};

export default ListarPatrimonio;
