import { Dropdown } from 'react-bootstrap';

type ContextMenuType = {
  name: string;
  disabled: boolean;
  action: () => void;
};

type PropsType = {
  options: ContextMenuType[];
  show: boolean;
  position: {x: number, y: number};
};

const ContextMenu = ({ options, show, position }: PropsType) => {
  return (
    <Dropdown.Menu show={show} style={{top: position.y, left: position.x}}>
      {options.map((item, index) => {
        return (
          <Dropdown.Item key={index} onClick={item.action} disabled={item.disabled}>{item.name}</Dropdown.Item>
        );
      })}
    </Dropdown.Menu>
  );
};

export default ContextMenu;
