import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Table, Alert } from 'react-bootstrap';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { ImpostoType } from '../../../types/ImpostoType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import ModalYesNo from '../../../components/ModalYesNo';
import { AlertType } from '../../../types/AlertType';
import { formatNumber } from '../../../helpers/Util';
import ContextMenu from '../../../components/ContextMenu';

const GET_IMPOSTOS = gql`
  query BuscarImpostos($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarImpostos(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        data
        total_compra
        total_venda
        resultado
        acumulado
        saldo_parcial
        saldo_final
        devido
        retido
        compensar
        pagar
        status
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const DELETE_IMPOSTO = gql`
  mutation ExcluirImposto($id: String!) {
    ExcluirImposto(id: $id) {
      code
      message
      stack
    }
  }
`;

const PAGE_SIZE = 10;

const FILTER = {
  $regex: `.*.*`,
  $options: 'i',
};

const SORT = {
  data: -1
};

const ListarImposto = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [impostoId, setImpostoId] = useState<string | null>(null);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [showContext, setShowContext] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowContext(false);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
    setShowContext(false);
  });

  const { data, loading, refetch } = useQuery(GET_IMPOSTOS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [deleteImposto, { loading: deleting }] = useMutation(DELETE_IMPOSTO, {
    onCompleted: (res) => {
      refetch();
      setImpostoId(null);
      setAlert({ message: res.ExcluirImposto.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const imposto: {
    items: ImpostoType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarImpostos.values || [],
    page: data?.BuscarImpostos.aggregate.page || 1,
    count: data?.BuscarImpostos.aggregate.count || 0,
    total: data?.BuscarImpostos.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
};

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      $regex: `.*${search}.*`,
      $options: 'i',
    };
    refetch({
      filter,
      page: 1,
    });
};

  const handleDelete = () => {
    setAlert(null);
    setShowDelete(false);
    deleteImposto({
      variables: {
        id: impostoId,
      },
    });
  };

  const handleContext = (e: any, id: string, status: string) => {
    e.preventDefault();
    setAlert(null);
    setImpostoId(id);
    setPosition({ x: e.pageX, y: e.pageY });
    setShowContext(true && status !== 'INATIVO');
  };
  
  const actionDelete = () => {
    setShowContext(false);
    setShowDelete(true);
  };

  const menuOptions = [
    {name: 'Excluir', disabled: false, action: actionDelete},
  ];

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th>Ref.</th>
              <th>Compra</th>
              <th>Venda</th>
              <th>Resultado</th>
              <th>Acumulado</th>
              <th>Parcial</th>
              <th>IR Devido</th>
              <th>IR Retido</th>
              <th>IR Compensar</th>
              <th>IR Pagar</th>
              <th>Final</th>
            </tr>
          </thead>
          <tbody>
            {imposto.items.map(item => {
              return (
                <tr key={item.id} className={styles.row} onContextMenu={(event) => handleContext(event, item.id, item.status)}>
                  <td className={styles.textColumn}>{item.data.substring(0, 7)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.total_compra, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.total_venda, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.resultado, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.acumulado, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.saldo_parcial, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.devido, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.retido, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.compensar, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.pagar, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.saldo_final, 2)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ContextMenu
          options={menuOptions}
          show={showContext && !loading && !deleting}
          position={position}
        />
        <Pagination
          total={imposto.total}
          page={imposto.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalYesNo
          title='Confirma exclusão do registro?'
          show={showDelete}
          onNo={setShowDelete}
          onYes={handleDelete}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading || deleting} />
      </Container>
   </Layout>
  )
};

export default ListarImposto;
