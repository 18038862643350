import moment from 'moment-timezone';

export const formatNumber = (value: number, digits: number, show: boolean = true) => {
  if (value) {
    return (value.toLocaleString('en-us', { minimumFractionDigits: digits }));
  } else {
    return (show ? '0.00' : '-');
  };
};

export const truncate = (value: number) => {
  return (Math.trunc(value * 100) / 100);
};

export const calcISS = (value: number, data: string) => {
  let val1 = value * 2.60 / 100.00;
  if (moment(data) < moment('2023-01-01'))
    val1 = value * 2.00 / 100.00;

  const val2 = value * (0.65 + 4.00) / 100.00;

  const trunc1 = Math.trunc(val1 * 100.00) / 100.00;
  const trunc2 = Math.trunc(val2 * 100.00) / 100.00;

  return (trunc1 + trunc2);
};
