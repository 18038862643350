import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment-timezone';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { REGEX_DATA } from '../../../helpers/Validation';

const CREATE_PATRIMONIO = gql`
  mutation CadastrarPatrimonio($input: PatrimonioInput!) {
    CadastrarPatrimonio(input: $input) {
      code
      message
      stack
    }
  }
`;

const CadastrarPatrimonio = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [createPatrimonio, { loading: creating }] = useMutation(CREATE_PATRIMONIO, {
    onCompleted: (res) => {
      setAlert({ message: res.CadastrarPatrimonio.message, variant: 'success' });
    },
    onError: (err) => {
      setIsDisabled(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      data: yup.string().required().matches(REGEX_DATA),
      descricao: yup.string().required(),
      tipo: yup.string().required(),
      valor: yup.number().positive().required(),
    }),
    initialValues: {
      data: moment().format('YYYY-MM-DD'),
      descricao: '',
      tipo: 'C',
      valor: '',
    },
    onSubmit: values => {
      setAlert(null);
      setIsDisabled(true);
      createPatrimonio({
        variables: {
          input: {
            data: values.data,
            descricao: values.descricao,
            tipo: values.tipo,
            valor: Number(values.valor),
          },
        },
      });
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setAlert(null);
    setIsDisabled(false);
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Cadastrar Patrimônio</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='data'>
                          <Form.Label>Data:</Form.Label>
                          <Form.Control
                            type='text'
                            name='data'
                            placeholder='aaaa-mm-dd'
                            value={formik.values.data}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.data && !formik.errors.data}
                            isInvalid={formik.touched.data && formik.errors.data !== undefined}
                            autoFocus
                            maxLength={10}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='descricao'>
                          <Form.Label>Descrição:</Form.Label>
                          <Form.Control
                            type='text'
                            name='descricao'
                            placeholder='descrição'
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.descricao && !formik.errors.descricao}
                            isInvalid={formik.touched.descricao && formik.errors.descricao !== undefined}
                            maxLength={36}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='tipo'>
                          <Form.Label>Tipo:</Form.Label>
                          <Form.Select
                            name='tipo'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.tipo || !formik.errors.tipo}
                            value={formik.values.tipo}
                          >
                            <option value='C'>Crédito</option>
                            <option value='D'>Débito</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='valor'>
                          <Form.Label>Valor:</Form.Label>
                          <Form.Control
                            type='text'
                            name='valor'
                            placeholder='0.00'
                            value={formik.values.valor}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.valor && !formik.errors.valor}
                            isInvalid={formik.touched.valor && formik.errors.valor !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled}>Cadastrar</Button>
            <Button variant='outline-primary' onClick={() => handleClear()} disabled={creating}>Limpar</Button>
          </div>
        </Form>
        <Spinner show={creating} />
      </Container>
    </Layout>
  );
}

export default CadastrarPatrimonio;
