import { Pagination as ReactPage } from 'react-bootstrap';
import classNames from 'classnames';

import styles from './index.module.scss';
import { PagedList } from '../../helpers/PagedList';

type PropsType = {
  total: number;
  page: number;
  size: number;
  onPaginate: (page: number) => void;
};

const Pagination = ({ total, page, size, onPaginate }: PropsType) => {
  const pagedList = new PagedList(total, page, size);

  if (total <= size) {
    return null;
  };

  return (
    <ReactPage className={classNames('justify-content-center', styles.container)}>
      {pagedList.CurrentPage > 1 &&
        <ReactPage.First onClick={() => onPaginate(1)} />
      }
      {pagedList.CurrentPage > 1 &&
        <ReactPage.Prev onClick={() => onPaginate(pagedList.CurrentPage - 1)} />
      }
      {pagedList.Range.map(page => {
        return (
          <ReactPage.Item
            key={`page-${page}`}
            active={page === pagedList.CurrentPage}
            onClick={() => onPaginate(page)}
          >
            {page}
          </ReactPage.Item>
        )
      })}
      {pagedList.CurrentPage < pagedList.TotalPages &&
        <ReactPage.Next onClick={() => onPaginate(pagedList.CurrentPage + 1)} />
      }
      {pagedList.CurrentPage < pagedList.TotalPages &&
        <ReactPage.Last onClick={() => onPaginate(pagedList.TotalPages)} />
      }
    </ReactPage>
  );
};

export default Pagination;
