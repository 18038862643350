import { FunctionComponent } from 'react';

import Login from './pages/Login';
import Logout from './pages/Logout';
import Home from './pages/Home';

import ListarAnalise from './pages/Analise/Listar';
import CadastrarAnalise from './pages/Analise/Cadastrar';
import AlterarAnalise from './pages/Analise/Alterar';
import TransportarAnalise from './pages/Analise/Transportar';

import ListarPosicao from './pages/Posicao/Listar';
import CadastrarPosicao from './pages/Posicao/Cadastrar';
import AlterarPosicao from './pages/Posicao/Alterar';
import TransportarPosicao from './pages/Posicao/Transportar';
import ZerarPosicao from './pages/Posicao/Zerar';
import HistoricoPosicao from './pages/Posicao/Historico';

import ListarCorretagem from './pages/Corretagem/Listar';
import AlterarCorretagem from './pages/Corretagem/Alterar';

import ListarResultado from './pages/Resultado/Listar';
import AlterarResultado from './pages/Resultado/Alterar';

import ListarImposto from './pages/Imposto/Listar';

import ListarAtivo from './pages/Ativo/Listar';
import CadastrarAtivo from './pages/Ativo/Cadastrar';
import AlterarAtivo from './pages/Ativo/Alterar';

import ListarSetor from './pages/Setor/Listar';
import CadastrarSetor from './pages/Setor/Cadastrar';
import AlterarSetor from './pages/Setor/Alterar';

import ListarResumo from './pages/Painel/Resumo';
import ListarMetas from './pages/Painel/Metas';
import ListarPerformance from './pages/Painel/Performance';

import ListarPatrimonio from './pages/Patrimonio/Listar';
import CadastrarPatrimonio from './pages/Patrimonio/Cadastrar';
import AlterarPatrimonio from './pages/Patrimonio/Alterar';

import ListarProcesso from './pages/Admin/Processo';
import ExecutarScript from './pages/Admin/Script';

type RouteType = {
  path: string;
  name: string;
  Component: FunctionComponent<unknown>;
  authenticated: boolean;
};

type RoutesType = RouteType[];

export const routes: RoutesType = [
  {
    path: '/',
    name: 'Home',
    Component: Home,
    authenticated: false,
  },
  {
    path: '/login',
    name: 'Login',
    Component: Login,
    authenticated: false,
  },
  {
    path: '/logout',
    name: 'Login',
    Component: Logout,
    authenticated: false,
  },
  {
    path: '/analise/listar',
    name: 'ListarAnalise',
    Component: ListarAnalise,
    authenticated: true,
  },
  {
    path: '/analise/cadastrar',
    name: 'CadastrarAnalise',
    Component: CadastrarAnalise,
    authenticated: true,
  },
  {
    path: '/analise/alterar/:id',
    name: 'AlterarAnalise',
    Component: AlterarAnalise,
    authenticated: true,
  },
  {
    path: '/analise/transportar/:id',
    name: 'TransportarAnalise',
    Component: TransportarAnalise,
    authenticated: true,
  },
  {
    path: '/posicao/listar',
    name: 'ListarPosicao',
    Component: ListarPosicao,
    authenticated: true,
  },
  {
    path: '/posicao/cadastrar',
    name: 'CadastrarPosicao',
    Component: CadastrarPosicao,
    authenticated: true,
  },
  {
    path: '/posicao/alterar/:id',
    name: 'AlterarPosicao',
    Component: AlterarPosicao,
    authenticated: true,
  },
  {
    path: '/posicao/transportar/:id',
    name: 'TransportarPosicao',
    Component: TransportarPosicao,
    authenticated: true,
  },
  {
    path: '/posicao/zerar/:id',
    name: 'ZerarPosicao',
    Component: ZerarPosicao,
    authenticated: true,
  },  {
    path: '/posicao/historico',
    name: 'HistoricoPosicao',
    Component: HistoricoPosicao,
    authenticated: true,
  },
  {
    path: '/corretagem/listar',
    name: 'ListarCorretagem',
    Component: ListarCorretagem,
    authenticated: true,
  },
  {
    path: '/corretagem/alterar/:id',
    name: 'AlterarCorretagem',
    Component: AlterarCorretagem,
    authenticated: true,
  },
  {
    path: '/imposto/listar',
    name: 'ListarImposto',
    Component: ListarImposto,
    authenticated: true,
  },
  {
    path: '/resultado/listar',
    name: 'ListarResultado',
    Component: ListarResultado,
    authenticated: true,
  },
  {
    path: '/resultado/alterar/:id',
    name: 'AlterarResultado',
    Component: AlterarResultado,
    authenticated: true,
  },
  {
    path: '/ativo/listar',
    name: 'ListarAtivo',
    Component: ListarAtivo,
    authenticated: true,
  },
  {
    path: '/ativo/cadastrar',
    name: 'CadastrarAtivo',
    Component: CadastrarAtivo,
    authenticated: true,
  },
  {
    path: '/ativo/alterar/:id',
    name: 'AlterarAtivo',
    Component: AlterarAtivo,
    authenticated: true,
  },
  {
    path: '/setor/listar',
    name: 'ListarSetor',
    Component: ListarSetor,
    authenticated: true,
  },
  {
    path: '/setor/cadastrar',
    name: 'CadastrarSetor',
    Component: CadastrarSetor,
    authenticated: true,
  },
  {
    path: '/setor/alterar/:id',
    name: 'AlterarSetor',
    Component: AlterarSetor,
    authenticated: true,
  },
  {
    path: '/painel/resumo',
    name: 'ListarResumo',
    Component: ListarResumo,
    authenticated: true,
  },
  {
    path: '/painel/metas',
    name: 'ListarMetas',
    Component: ListarMetas,
    authenticated: true,
  },
  {
    path: '/painel/performance',
    name: 'ListarPerformance',
    Component: ListarPerformance,
    authenticated: true,
  },
  {
    path: '/patrimonio/listar',
    name: 'ListarPatrimonio',
    Component: ListarPatrimonio,
    authenticated: true,
  },
  {
    path: '/patrimonio/cadastrar',
    name: 'CadastrarPatrimonio',
    Component: CadastrarPatrimonio,
    authenticated: true,
  },
  {
    path: '/patrimonio/alterar/:id',
    name: 'AlterarPatrimonio',
    Component: AlterarPatrimonio,
    authenticated: true,
  },
  {
    path: '/admin/processo',
    name: 'ListarProcesso',
    Component: ListarProcesso,
    authenticated: true,
  },
  {
    path: '/admin/script',
    name: 'ExecutarScript',
    Component: ExecutarScript,
    authenticated: true,
  },
];
