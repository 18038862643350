import { useContext } from 'react';
import { createContext, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import { LoginType } from '../../types/LoginType';

const ME = gql`
  query Me {
    Me {
      id
      login
      nome
      grupo
    }
  }
`;

const inicialValues = {
  id: '',
  login: '',
  nome: '',
  grupo: 'USER',
};

const LoginContext = createContext<LoginType>(inicialValues);

const useLogin = () => {
  let context = useContext(LoginContext);

  const [me] = useLazyQuery(ME, {
    variables: {},
    onCompleted: (res) => {
      context.id = res.Me.id;
      context.login = res.Me.login;
      context.nome = res.Me.nome;
      context.grupo = res.Me.grupo;
    },
    onError: (err) => {
      context.id = inicialValues.id;
      context.login = inicialValues.login;
      context.nome = inicialValues.nome;
      context.grupo = inicialValues.grupo;
    }
  });

  useEffect(() => {
    me();
  }, [me]);

  return context;
};

export { useLogin };
