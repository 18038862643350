import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';

const CREATE_SETOR = gql`
  mutation CadastrarSetor($input: SetorInput!) {
    CadastrarSetor(input: $input) {
      code
      message
      stack
    }
  }
`;

const CadastrarSetor = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [createSetor, { loading: creating }] = useMutation(CREATE_SETOR, {
    onCompleted: (res) => {
      setAlert({ message: res.CadastrarSetor.message, variant: 'success' });
    },
    onError: (err) => {
      setIsDisabled(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      nome: yup.string().required(),
      icone: yup.string().required(),
    }),
    initialValues: {
      nome: '',
      icone: '',
    },
    onSubmit: values => {
      setAlert(null);
      setIsDisabled(true);
      createSetor({
        variables: {
          input: {
            nome: values.nome,
            icone: values.icone,
          },
        },
      });
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setAlert(null);
    setIsDisabled(false);
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Cadastrar Setor</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled}>
                    <Row>
                      <Col md={4}>
                          <Form.Group className='mb-4' controlId='nome'>
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control
                              type='text'
                              name='nome'
                              placeholder='nome'
                              value={formik.values.nome}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isValid={formik.touched.nome && !formik.errors.nome}
                              isInvalid={formik.touched.nome && formik.errors.nome !== undefined}
                              autoFocus
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Form.Group controlId='icone'>
                            <Form.Label>Ícone:</Form.Label>
                            <Form.Control
                              type='text'
                              name='icone'
                              placeholder='ícone'
                              value={formik.values.icone}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isValid={formik.touched.icone && !formik.errors.icone}
                              isInvalid={formik.touched.icone && formik.errors.icone !== undefined}
                            />
                          </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled}>Cadastrar</Button>
            <Button variant='outline-primary' onClick={() => handleClear()} disabled={creating}>Limpar</Button>
          </div>
        </Form>
        <Spinner show={creating} />
      </Container>
    </Layout>
  );
}

export default CadastrarSetor;
