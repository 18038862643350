import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

type PropsType = {
  children: ReactNode | ReactNode[];
};

const AuthenticatedRoute = (props: PropsType) => {
  const [ cookies ] = useCookies(['tradecontrol']);

  if (!cookies?.tradecontrol) {
    return <Navigate to='/logout' replace />
  };

  return (<>{props.children}</>);
};

export default AuthenticatedRoute;
