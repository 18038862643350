import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment-timezone';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { REGEX_ATIVO, REGEX_DATA } from '../../../helpers/Validation';

const GET_ANALISE = gql`
  query BuscarAnalise($id: String!) {
    BuscarAnalise(id: $id) {
      id
      data
      tipo
      ativo {
        id
      }
      preco_entrada
      stop_loss
      saida_parcial
      saida_final
    }
  }
`;

const TRANSPORT_ANALISE = gql`
  mutation TransportarAnalise($id: String!, $input: PosicaoInput!) {
    TransportarAnalise(id: $id, input: $input) {
      code
      message
      stack
    }
  }
`;

const TransportarAnalise = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams<{ id: string }>();

  const { loading } = useQuery(GET_ANALISE, {
    variables: {
      id: params?.id,
    },
    onCompleted: (res) => {
      formik.setValues({
        data: moment().format('YYYY-MM-DD'),
        tipo: res.BuscarAnalise.tipo,
        ativo: res.BuscarAnalise.ativo.id,
        preco_entrada: res.BuscarAnalise.preco_entrada.toFixed(2),
        stop_loss: res.BuscarAnalise.stop_loss.toFixed(2),
        qtde_parcial: '',
        saida_parcial: res.BuscarAnalise.saida_parcial.toFixed(2),
        qtde_final: '',
        saida_final: res.BuscarAnalise.saida_final.toFixed(2),
      });
    },
    onError: (err) => {
      setIsDisabled(true);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [transportAnalise, { loading: transporting }] = useMutation(TRANSPORT_ANALISE, {
    onCompleted: (res) => {
      setIsDisabled(true);
      setAlert({ message: res.TransportarAnalise.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      data: yup.string().required().matches(REGEX_DATA),
      tipo: yup.string().required(),
      ativo: yup.string().required().matches(REGEX_ATIVO),
      preco_entrada: yup.number().positive().required(),
      stop_loss: yup.number().positive().required(),
      qtde_parcial: yup.number().integer().positive().required(),
      saida_parcial: yup.number().positive().required(),
      qtde_final: yup.number().integer().positive().required(),
      saida_final: yup.number().positive().required(),
    }),
    initialValues: {
      data: '',
      tipo: 'COMPRA',
      ativo: '',
      preco_entrada: '',
      stop_loss: '',
      saida_parcial: '',
      qtde_parcial: '',
      saida_final: '',
      qtde_final: '',
    },
    onSubmit: values => {
      setAlert(null);
      transportAnalise({
        variables: {
          id: params?.id,
          input: {
            data: values.data,
            tipo: values.tipo,
            ativo: values.ativo,
            preco_entrada: Number(values.preco_entrada),
            stop_loss: Number(values.stop_loss),
            qtde_parcial: Number(values.qtde_parcial),
            saida_parcial: Number(values.saida_parcial),
            qtde_final: Number(values.qtde_final),
            saida_final: Number(values.saida_final),
            status: 'ATIVO',
          },
        },
      });
    },
  });

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Transportar Análise</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loading || transporting}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='data'>
                          <Form.Label>Data:</Form.Label>
                          <Form.Control
                            type='text'
                            name='data'
                            placeholder='aaaa-mm-dd'
                            value={formik.values.data}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.data && !formik.errors.data}
                            isInvalid={formik.touched.data && formik.errors.data !== undefined}
                            autoFocus
                            maxLength={10}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='tipo'>
                          <Form.Label>Tipo:</Form.Label>
                          <Form.Select
                            name='tipo'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.tipo || !formik.errors.tipo}
                            value={formik.values.tipo}
                          >
                            <option value='COMPRA'>Compra</option>
                            <option value='VENDA'>Venda</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='ativo'>
                          <Form.Label>Ativo:</Form.Label>
                          <Form.Control
                            type='text'
                            name='ativo'
                            placeholder='ativo'
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.ativo && !formik.errors.ativo}
                            isInvalid={formik.touched.ativo && formik.errors.ativo !== undefined}
                            maxLength={6}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='preco_entrada'>
                          <Form.Label>Preço Entrada:</Form.Label>
                          <Form.Control
                            type='text'
                            name='preco_entrada'
                            placeholder='0.00'
                            value={formik.values.preco_entrada}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.preco_entrada && !formik.errors.preco_entrada}
                            isInvalid={formik.touched.preco_entrada && formik.errors.preco_entrada !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='stop_loss'>
                          <Form.Label>Stop Loss:</Form.Label>
                          <Form.Control
                            type='text'
                            name='stop_loss'
                            placeholder='0.00'
                            value={formik.values.stop_loss}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.stop_loss && !formik.errors.stop_loss}
                            isInvalid={formik.touched.stop_loss && formik.errors.stop_loss !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='qtde_parcial'>
                          <Form.Label>Qtde Parcial:</Form.Label>
                          <Form.Control
                            type='text'
                            name='qtde_parcial'
                            placeholder='0'
                            value={formik.values.qtde_parcial}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.qtde_parcial && !formik.errors.qtde_parcial}
                            isInvalid={formik.touched.qtde_parcial && formik.errors.qtde_parcial !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='saida_parcial'>
                          <Form.Label>Saída Parcial:</Form.Label>
                          <Form.Control
                            type='text'
                            name='saida_parcial'
                            placeholder='0.00'
                            value={formik.values.saida_parcial}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.saida_parcial && !formik.errors.saida_parcial}
                            isInvalid={formik.touched.saida_parcial && formik.errors.saida_parcial !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='qtde_final'>
                          <Form.Label>Qtde Final:</Form.Label>
                          <Form.Control
                            type='text'
                            name='qtde_final'
                            placeholder='0'
                            value={formik.values.qtde_final}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.qtde_final && !formik.errors.qtde_final}
                            isInvalid={formik.touched.qtde_final && formik.errors.qtde_final !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='saida_final'>
                          <Form.Label>Saída Final:</Form.Label>
                          <Form.Control
                            type='text'
                            name='saida_final'
                            placeholder='0.00'
                            value={formik.values.saida_final}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.saida_final && !formik.errors.saida_final}
                            isInvalid={formik.touched.saida_final && formik.errors.saida_final !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loading || transporting}>Transportar</Button>
            <Button variant='outline-primary' disabled>Limpar</Button>
          </div>
        </Form>
        <Spinner show={loading || transporting} />
      </Container>
    </Layout>
  );
}

export default TransportarAnalise;
