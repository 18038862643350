import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AnaliseType } from '../../../types/AnaliseType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import ModalYesNo from '../../../components/ModalYesNo';
import { AlertType } from '../../../types/AlertType';
import { formatNumber } from '../../../helpers/Util';

library.add(fas);

const GET_ANALISES = gql`
  query BuscarAnalises($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarAnalises(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        data
        tipo
        ativo {
          id
          cotacao
          setor {
            nome
            icone
          }
        }
        preco_entrada
        stop_loss
        saida_parcial
        saida_final
        ganho_projetado
        perda_projetada
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const DELETE_ANALISE = gql`
  mutation ExcluirAnalise($id: String!) {
    ExcluirAnalise(id: $id) {
      code
      message
      stack
    }
  }
`;

const PAGE_SIZE = 4;

const FILTER = {};

const SORT = {
  data: -1,
  id: -1,
};

const ListarAnalise = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [analiseId, setAnaliseId] = useState<string | null>(null);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const navigate = useNavigate();

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
  });

  const { data, loading, refetch } = useQuery(GET_ANALISES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [deleteAnalise, { loading: deleting }] = useMutation(DELETE_ANALISE, {
    onCompleted: (res) => {
      refetch();
      setAnaliseId(null);
      setAlert({ message: res.ExcluirAnalise.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const analise: {
    items: AnaliseType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarAnalises.values || [],
    page: data?.BuscarAnalises.aggregate.page || 1,
    count: data?.BuscarAnalises.aggregate.count || 0,
    total: data?.BuscarAnalises.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
  };

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      ...FILTER,
      'ativo.id': {
        $regex: `.*${search}.*`,
        $options: 'i'
      },
    };
    refetch({
      filter,
      page: 1,
    });
  };

  const handleDelete = () => {
    setAlert(null);
    setShowDelete(false);
    deleteAnalise({
      variables: {
        id: analiseId,
      }
    });
  };

  const renderCard = (item: AnaliseType) => {
    return (
      <Card className='shadow'>
        <Card.Header className={styles.cardHeader}>
          <Row>
            <Col md='1' className='text-center'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>{item.ativo?.setor?.nome}</Tooltip>}
              >
                <FontAwesomeIcon icon={item.ativo?.setor?.icone as IconProp} className={styles.icon} />
              </OverlayTrigger>
            </Col>
            <Col md='4' className='text-start'>
              {item.ativo.id}
            </Col>
            <Col md='3' className='text-center'>
              {moment(item.data).format('MM-DD')}
            </Col>
            <Col md='4' className='text-end'>
              {(item.tipo === 'COMPRA' ? 'Compra' : 'Venda')}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className={styles.row}>
            <Col md='12' className='text-center'>
              <div>Preço Atual</div>
              <div>{formatNumber(item.ativo.cotacao, 2)}</div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md='6' className='text-center'>
              <div>Preço Entrada</div>
              <div>{formatNumber(item.preco_entrada, 2)}</div>
            </Col>
            <Col md='6' className='text-center'>
              <div>Stop Loss</div>
              <div>{formatNumber(item.stop_loss, 2)}</div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md='6' className='text-center'>
              <div>Saída Parcial</div>
              <div>{formatNumber(item.saida_parcial, 2)}</div>
            </Col>
            <Col md='6' className='text-center'>
              <div>Saída Final</div>
              <div>{formatNumber(item.saida_final, 2)}</div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md='6' className={item.ganho_projetado >= 0 ? 'text-center text-success' : 'text-center text-danger'}>
              <div>Ganho</div>
              <div>{`${Math.abs(item.ganho_projetado).toFixed(1)}%`}</div>
            </Col>
            <Col md='6' className={item.perda_projetada >= 0 ? 'text-center text-success' : 'text-center text-danger'}>
              <div>Perda</div>
              <div>{`${Math.abs(item.perda_projetada).toFixed(1)}%`}</div>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row className='text-center'>
            <Col md='4'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Alterar</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='pencil'
                  size='lg'
                  className={deleting ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={() => navigate(`/analise/alterar/${item.id}`)}
                />
              </OverlayTrigger>
            </Col>
            <Col md='4'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Excluir</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='trash-can'
                  size='lg'
                  className={deleting ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={() => {
                    setAnaliseId(item.id);
                    setShowDelete(true);
                  }}
                />
              </OverlayTrigger>
            </Col>
            <Col md='4'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Transportar</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='share-square'
                  size='lg'
                  className={deleting ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={() => navigate(`/analise/transportar/${item.id}`)}
                />
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Row>
          <Col md='12'>
            <Card>
              <Card.Header className={styles.header}>Ativos Recomendados</Card.Header>
              <Card.Body>
                <Row>
                  {analise.items.map(item => {
                    return (
                      <Col md='3' key={item.id}>
                        {renderCard(item)}
                      </Col>
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Pagination
          total={analise.total}
          page={analise.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalYesNo
          title='Confirma exclusão do registro?'
          show={showDelete}
          onNo={setShowDelete}
          onYes={handleDelete}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading || deleting} />
      </Container>
   </Layout>
  );
};

export default ListarAnalise;
