import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { REGEX_DATA } from '../../../helpers/Validation';

const GET_PATRIMONIO = gql`
  query BuscarPatrimonio($id: String!) {
    BuscarPatrimonio(id: $id) {
      id
      data
      descricao
      tipo
      valor
      saldo
    }
  }
`;

const UPDATE_PATRIMONIO = gql`
  mutation AlterarPatrimonio($id: String!, $input: PatrimonioInput!) {
    AlterarPatrimonio(id: $id, input: $input) {
      code
      message
      stack
    }
  }
`;

const AlterarPatrimonio = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const[isDisabled, setIsDisabled] = useState(false);
  const params = useParams<{ id: string }>();

  const { loading } = useQuery(GET_PATRIMONIO, {
    variables: {
      id: params?.id,
    },
    onCompleted: (res) => {
      formik.setValues({
        data: res.BuscarPatrimonio.data,
        descricao: res.BuscarPatrimonio.descricao,
        tipo: res.BuscarPatrimonio.tipo,
        valor: res.BuscarPatrimonio.valor.toFixed(2),
      });
    },
    onError: (err) => {
      setIsDisabled(true);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [updatePatrimonio, { loading: updating }] = useMutation(UPDATE_PATRIMONIO, {
    onCompleted: (res) => {
      setAlert({ message: res.AlterarPatrimonio.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      data: yup.string().required().matches(REGEX_DATA),
      descricao: yup.string().required(),
      tipo: yup.string().required(),
      valor: yup.number().positive().required(),
    }),
    initialValues: {
      data: '',
      descricao: '',
      tipo: 'C',
      valor: '',
    },
    onSubmit: values => {
      setAlert(null);
      updatePatrimonio({
        variables: {
          id: params?.id,
          input: {
            data: values.data,
            descricao: values.descricao,
            tipo: values.tipo,
            valor: Number(values.valor),
          },
        },
      });
    },
  });

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Alterar Patrimônio</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loading || updating}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='data'>
                          <Form.Label>Data:</Form.Label>
                          <Form.Control
                            type='text'
                            name='data'
                            placeholder='aaaa-mm-dd'
                            value={formik.values.data}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.data && !formik.errors.data}
                            isInvalid={formik.touched.data && formik.errors.data !== undefined}
                            autoFocus
                            maxLength={10}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='descricao'>
                          <Form.Label>Descrição:</Form.Label>
                          <Form.Control
                            type='text'
                            name='descricao'
                            placeholder='descrição'
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.descricao && !formik.errors.descricao}
                            isInvalid={formik.touched.descricao && formik.errors.descricao !== undefined}
                            maxLength={36}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='tipo'>
                          <Form.Label>Tipo:</Form.Label>
                          <Form.Select
                            name='tipo'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.tipo || !formik.errors.tipo}
                            value={formik.values.tipo}
                          >
                            <option value='C'>Crédito</option>
                            <option value='D'>Débito</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='valor'>
                          <Form.Label>Valor:</Form.Label>
                          <Form.Control
                            type='text'
                            name='valor'
                            placeholder='0.00'
                            value={formik.values.valor}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.valor && !formik.errors.valor}
                            isInvalid={formik.touched.valor && formik.errors.valor !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loading || updating}>Alterar</Button>
            <Button variant='outline-primary' disabled>Limpar</Button>
          </div>
        </Form>
        <Spinner show={loading || updating} />
      </Container>
    </Layout>
  );
}

export default AlterarPatrimonio;
