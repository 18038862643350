import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Button, Card } from 'react-bootstrap';

import styles from './index.module.scss';
import Layout from '../../components/Layout';

const cookie = new Cookies();

const Logout = () => {
  const navigate = useNavigate();

  cookie.remove('tradecontrol');

  return (
    <Layout>
      <Card className={styles.card}>
        <Card.Body>
          Você não está mais conectado ao site.
          <div className={styles.buttonRow}>
            <Button className={styles.button} variant='outline-primary' onClick={() => navigate('/login')}>Login</Button>
          </div>
        </Card.Body>
      </Card>
    </Layout>
  );
};

export default Logout;
