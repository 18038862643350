import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { SetorType } from '../../../types/SetorType';
import { REGEX_ATIVO } from '../../../helpers/Validation';
import { AtivoType } from '../../../types/AtivoType';

const LIST_SETORES = gql`
  query ListarSetores($sort: JsonValue) {
    ListarSetores(sort: $sort) {
      values {
        id
        nome
      }
    }
  }
`;

const CREATE_ATIVO = gql`
  mutation CadastrarAtivo($input: CadastrarAtivoInput!) {
    CadastrarAtivo(input: $input) {
      code
      message
      stack
    }
  }
`;

const LIST_ATIVOS = gql`
  query ListarAtivos {
    ListarAtivos {
      values {
        id
      }
    }
  }
`;

const CadastrarAtivo = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [setores, setSetores] = useState<SetorType[] | null>([]);
  const [ativos, setAtivos] = useState<AtivoType[] | null>([]);

  const { loading: loadingAtivos } = useQuery(LIST_ATIVOS, {
    onCompleted: (res) => {
      setAtivos(res.ListarAtivos.values);
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const { loading: loadingSetores } = useQuery(LIST_SETORES, {
    variables: {
      sort: {
        nome: 1,
      },
    },
    onCompleted: (res) => {
      setSetores(res.ListarSetores.values);
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [createAtivo, { loading: creating }] = useMutation(CREATE_ATIVO, {
    onCompleted: (res) => {
      setAlert({ message: res.CadastrarAtivo.message, variant: 'success' });
    },
    onError: (err) => {
      setIsDisabled(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      ativo: yup.string().required().matches(REGEX_ATIVO),
      nome: yup.string().required(),
      cotacao: yup.number().positive().required(),
      setor: yup.string(),
    }),
    initialValues: {
      ativo: '',
      nome: '',
      cotacao: '',
      setor: '',
    },
    onSubmit: values => {
      setAlert(null);
      setIsDisabled(true);
      createAtivo({
        variables: {
          input: {
            id: values.ativo,
            nome: values.nome,
            cotacao: Number(values.cotacao),
            setor: values.setor || null,
          },
        },
      });
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setAlert(null);
    setIsDisabled(false);
  };

  const hasAtivo = () => {
    const find = ativos?.find(item => item.id === formik.values.ativo);
    if (!find) return false;
    return true;
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Cadastrar Ativo</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loadingSetores || loadingAtivos}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='ativo'>
                          <Form.Label>Ativo:</Form.Label>
                          <Form.Control
                            type='text'
                            name='ativo'
                            placeholder='ativo'
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.ativo && !formik.errors.ativo}
                            isInvalid={formik.touched.ativo && (formik.errors.ativo !== undefined || hasAtivo())}
                            autoFocus
                            maxLength={6}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='3'>
                        <Form.Group className='mb-4' controlId='nome'>
                          <Form.Label>Nome:</Form.Label>
                          <Form.Control
                            type='text'
                            name='nome'
                            placeholder='nome'
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.nome && !formik.errors.nome}
                            isInvalid={formik.touched.nome && formik.errors.nome !== undefined}
                            maxLength={32}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='cotacao'>
                          <Form.Label>Cotação:</Form.Label>
                          <Form.Control
                            type='text'
                            name='cotacao'
                            placeholder='0.00'
                            value={formik.values.cotacao}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.cotacao && !formik.errors.cotacao}
                            isInvalid={formik.touched.cotacao && formik.errors.cotacao !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='3'>
                        <Form.Group controlId='setor'>
                          <Form.Label>Setor:</Form.Label>
                          <Form.Select
                            name='setor'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.values.setor !== ''}
                            value={formik.values.setor}
                          >
                            <option value=''></option>
                            {setores?.map(item => {
                              return (
                                <option key={item.id} value={item.id}>{item.nome}</option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loadingSetores || loadingAtivos}>Cadastrar</Button>
            <Button variant='outline-primary' onClick={() => handleClear()} disabled={loadingSetores || creating || loadingAtivos}>Limpar</Button>
          </div>
        </Form>
        <Spinner show={loadingSetores || creating || loadingAtivos} />
      </Container>
    </Layout>
  );
}

export default CadastrarAtivo;
