import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment-timezone';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { REGEX_ATIVO, REGEX_DATA } from '../../../helpers/Validation';
import { useParametro }  from '../../../contexts/ParametroContext';
import { AtivoType } from '../../../types/AtivoType';

const CREATE_POSICAO = gql`
  mutation CadastrarPosicao($input: PosicaoInput!) {
    CadastrarPosicao(input: $input) {
      code
      message
      stack
    }
  }
`;

const LIST_ATIVOS = gql`
  query ListarAtivos {
    ListarAtivos {
      values {
        id
      }
    }
  }
`;

const CadastrarPosicao = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const parametro = useParametro();
  const [ativos, setAtivos] = useState<AtivoType[] | null>([]);

  const { loading: loadingAtivos } = useQuery(LIST_ATIVOS, {
    onCompleted: (res) => {
      setAtivos(res.ListarAtivos.values);
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [createPosicao, { loading: creating }] = useMutation(CREATE_POSICAO, {
    onCompleted: (res) => {
      setAlert({ message: res.CadastrarPosicao.message, variant: 'success' });
    },
    onError: (err) => {
      setIsDisabled(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      data: yup.string().required().matches(REGEX_DATA),
      tipo: yup.string().required(),
      ativo: yup.string().required().matches(REGEX_ATIVO),
      preco_entrada: yup.number().positive().required(),
      stop_loss: yup.number().positive().required(),
      qtde_parcial: yup.number().positive().required(),
      saida_parcial: yup.number().positive().required(),
      qtde_final: yup.number().positive().required(),
      saida_final: yup.number().positive().required(),
    }),
    initialValues: {
      data: moment().format('YYYY-MM-DD'),
      tipo: 'COMPRA',
      ativo: '',
      preco_entrada: '',
      stop_loss: '',
      qtde_parcial: '',
      saida_parcial: '',
      qtde_final: '',
      saida_final: '',
    },
    onSubmit: values => {
      setAlert(null);
      if (!hasAtivo()) return;
      setIsDisabled(true);
      createPosicao({
        variables: {
          input: {
            data: values.data,
            tipo: values.tipo,
            ativo: values.ativo,
            preco_entrada: Number(values.preco_entrada),
            stop_loss: Number(values.stop_loss),
            qtde_parcial: Number(values.qtde_parcial),
            saida_parcial: Number(values.saida_parcial),
            qtde_final: Number(values.qtde_final),
            saida_final: Number(values.saida_final),
            status: 'ATIVO',
          },
        },
      });
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setAlert(null);
    setIsDisabled(false);
  };
  
  const calcSaida = () => {
    if (formik.values.preco_entrada && formik.values.stop_loss) {
      formik.values.saida_parcial = (((Number(formik.values.preco_entrada) - Number(formik.values.stop_loss)) * parametro.relacaoParcial) + Number(formik.values.preco_entrada)).toFixed(2);
      formik.values.saida_final = (((Number(formik.values.preco_entrada) - Number(formik.values.stop_loss)) * parametro.relacaoFinal) + Number(formik.values.preco_entrada)).toFixed(2);
    };
  };

  const hasAtivo = () => {
    const find = ativos?.find(item => item.id === formik.values.ativo);
    if (!find) return false;
    return true;
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Cadastrar Posição</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loadingAtivos}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='data'>
                          <Form.Label>Data:</Form.Label>
                          <Form.Control
                            type='text'
                            name='data'
                            placeholder='aaaa-mm-dd'
                            value={formik.values.data}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.data && !formik.errors.data}
                            isInvalid={formik.touched.data && formik.errors.data !== undefined}
                            autoFocus
                            maxLength={10}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='tipo'>
                          <Form.Label>Tipo:</Form.Label>
                          <Form.Select
                            name='tipo'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.tipo || !formik.errors.tipo}
                            value={formik.values.tipo}
                          >
                            <option value='COMPRA'>Compra</option>
                            <option value='VENDA'>Venda</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='ativo'>
                          <Form.Label>Ativo:</Form.Label>
                          <Form.Control
                            type='text'
                            name='ativo'
                            placeholder='ativo'
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.ativo && !formik.errors.ativo && hasAtivo()}
                            isInvalid={formik.touched.ativo && (formik.errors.ativo !== undefined || !hasAtivo())}
                            maxLength={6}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='preco_entrada'>
                          <Form.Label>Preço Entrada:</Form.Label>
                          <Form.Control
                            type='text'
                            name='preco_entrada'
                            placeholder='0.00'
                            value={formik.values.preco_entrada}
                            onChange={formik.handleChange}
                            onBlur={e => {
                              formik.handleBlur(e);
                              calcSaida();
                            }}
                            isValid={formik.touched.preco_entrada && !formik.errors.preco_entrada}
                            isInvalid={formik.touched.preco_entrada && formik.errors.preco_entrada !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='stop_loss'>
                          <Form.Label>Stop Loss:</Form.Label>
                          <Form.Control
                            type='text'
                            name='stop_loss'
                            placeholder='0.00'
                            value={formik.values.stop_loss}
                            onChange={formik.handleChange}
                            onBlur={e => {
                              formik.handleBlur(e);
                              calcSaida();
                            }}
                            isValid={formik.touched.stop_loss && !formik.errors.stop_loss}
                            isInvalid={formik.touched.stop_loss && formik.errors.stop_loss !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='qtde_parcial'>
                          <Form.Label>Qtde Parcial:</Form.Label>
                          <Form.Control
                            type='text'
                            name='qtde_parcial'
                            placeholder='0'
                            value={formik.values.qtde_parcial}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.qtde_parcial && !formik.errors.qtde_parcial}
                            isInvalid={formik.touched.qtde_parcial && formik.errors.qtde_parcial !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='saida_parcial'>
                          <Form.Label>Saída Parcial:</Form.Label>
                          <Form.Control
                            type='text'
                            name='saida_parcial'
                            placeholder='0.00'
                            value={formik.values.saida_parcial}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.saida_parcial && !formik.errors.saida_parcial}
                            isInvalid={formik.touched.saida_parcial && formik.errors.saida_parcial !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='qtde_final'>
                          <Form.Label>Qtde Final:</Form.Label>
                          <Form.Control
                            type='text'
                            name='qtde_final'
                            placeholder='0'
                            value={formik.values.qtde_final}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.qtde_final && !formik.errors.qtde_final}
                            isInvalid={formik.touched.qtde_final && formik.errors.qtde_final !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='saida_final'>
                          <Form.Label>Saída Final:</Form.Label>
                          <Form.Control
                            type='text'
                            name='saida_final'
                            placeholder='0.00'
                            value={formik.values.saida_final}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.saida_final && !formik.errors.saida_final}
                            isInvalid={formik.touched.saida_final && formik.errors.saida_final !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loadingAtivos}>Cadastrar</Button>
            <Button variant='outline-primary' onClick={() => handleClear()} disabled={creating || loadingAtivos}>Limpar</Button>
          </div>
        </Form>
        <Spinner show={creating || loadingAtivos} />
      </Container>
    </Layout>
  );
}

export default CadastrarPosicao;
