import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import './index.scss';
import { routes } from './routes';
import { ApolloManager } from './contexts/ApolloManager';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import NoMatch from './pages/NoMatch';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <CookiesProvider>
    <BrowserRouter>
      <ApolloManager>
        <Routes>
          {routes.map(r => {
            if (r.authenticated) {
              return (
                <Route
                  key={r.name}
                  path={r.path}
                  element={
                    <AuthenticatedRoute>
                      {<r.Component />}
                    </AuthenticatedRoute>
                  }
                />
              );
            };
            return (
              <Route
                key={r.name}
                path={r.path}
                element={<r.Component />}
              />
            )
          })}
          <Route
            path='*'
            element={<NoMatch />}
          />
        </Routes>
      </ApolloManager>
    </BrowserRouter>
  </CookiesProvider>
);
