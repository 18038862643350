export class PagedList {
  public TotalItems: number;
  public CurrentPage: number;
  public PageSize: number;
  public TotalPages: number;
  public StartPage: number;
  public EndPage: number;
  public Visible: boolean;
  public Range: Array<number>;

  constructor(totalItems: number, page: number, pageSize: number) {
      var numPages = 5;
      var totalPages = Math.ceil(totalItems / pageSize);
      var currentPage = page;
      var startPage = 0;
      var endPage = 0;

      if (totalPages <= numPages) {
          startPage = 1;
          endPage = (totalPages < numPages ? totalPages : numPages);
      } else if (currentPage <= numPages) {
          startPage = 1;
          endPage = (totalPages < numPages ? totalPages : numPages);
      } else if (currentPage > numPages) {
          startPage = currentPage - numPages + 1;
          endPage = currentPage;
      }

      var range = [];
      for (var i = startPage; i <= endPage; i++) {
          range.push(i);
      }

      this.TotalItems = totalItems;
      this.CurrentPage = currentPage;
      this.PageSize = pageSize;
      this.TotalPages = totalPages;
      this.StartPage = startPage;
      this.EndPage = endPage;
      this.Visible = (endPage > 1 && totalItems > 0);
      this.Range = range;
  }
}
