import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Container, Alert, Card, Row, Col } from 'react-bootstrap';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend, Tooltip, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Filler } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import Spinner from '../../../components/Spinner';
import { AlertType } from '../../../types/AlertType';
import { LabelValueType, PeriodType } from '../../../types/PainelType';

const GET_METAS = gql`
  query BuscarMetas($filter: JsonValue) {
    BuscarMetas(filter: $filter) {
      evolucao {
        label
        value
      }
      rendimento {
        label
        value
      }
      ganho_perda {
        label
        value
      }
      relacao {
        label
        value
      }
    }
  }
`;

ChartJS.register(ArcElement, Legend, ChartDataLabels, Tooltip, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Filler);

const FILTER = {
  'evolucao': '12M',
  'rendimento': '12M',
  'ganho_perda': '12M',
  'relacao': '12M',
};

const ListarMetas = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [period, setPeriod] = useState<PeriodType>(FILTER);

  const { data, loading, refetch } = useQuery(GET_METAS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const handleChange = (name: string, value: string) => {
    const filter = {
      'evolucao': (name === 'evolucao' ? value : period.evolucao),
      'rendimento': (name === 'rendimento' ? value : period.rendimento),
      'ganho_perda': (name === 'ganho_perda' ? value : period.ganho_perda),
      'relacao': (name === 'relacao' ? value : period.relacao),
    }
    setPeriod(filter);
    refetch({
      filter,
    });
  };

  const renderEvolucao = (record: LabelValueType) => {
    const data = {
      labels: record?.label,
      datasets: [{
        data: record?.value,
        lineTension: 0.3,
        backgroundColor: 'rgba(226, 107, 10, 0.1)',
        borderColor: 'rgba(226, 107, 10, 1)',
        pointRadius: 3,
        pointBackgroundColor: 'rgba(226, 107, 10, 1)',
        pointBorderColor: 'rgba(226, 107, 10, 1)',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'rgba(226, 107, 10, 1)',
        pointHoverBorderColor: 'rgba(226, 107, 10, 1)',
        pointHitRadius: 10,
        pointBorderWidth: 2,
        fill: true,
      }],
    };

    return (
      <Row>
        <Line
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (tickValue, index, ticks) => {
                    return Number(tickValue).toLocaleString('en-US');
                  },
                },
              },
            },
          }}
        />
      </Row>
    );
  };

  const renderRendimento = (record: LabelValueType) => {
    const data = {
      labels: record?.label,
      datasets: [{
        data: record?.value,
        lineTension: 0.3,
        backgroundColor: 'rgba(226, 107, 10, 0.1)',
        borderColor: 'rgba(226, 107, 10, 1)',
        pointRadius: 3,
        pointBackgroundColor: 'rgba(226, 107, 10, 1)',
        pointBorderColor: 'rgba(226, 107, 10, 1)',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'rgba(226, 107, 10, 1)',
        pointHoverBorderColor: 'rgba(226, 107, 10, 1)',
        pointHitRadius: 10,
        pointBorderWidth: 2,
        fill: true,
      }],
    };

    return (
      <Row>
        <Line
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (tickValue, index, ticks) => {
                    return Number(tickValue).toLocaleString('en-US');
                  },
                },
              },
            },
          }}
        />
      </Row>
    );
  };

  const renderGanho = (record: LabelValueType) => {
    const data = {
      labels: record?.label,
      datasets: [{
        data: record?.value,
        backgroundColor: (context: any) => {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          return (value >= 0 ? 'rgba(226, 107,  10, 0.8)' : 'rgba(150, 54, 52, 0.8)');
        },
        hooverBackgroundColor: (context: any) => {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          return (value >= 0 ? 'rgba(226, 107,  10, 1)' : 'rgba(150, 54, 52, 1)');
        },
        hoverBorderColor: 'rgba(234, 236, 244, 0.05)',
        color: 'rgba(255, 255, 255, 1)',
      }],
    };

    return (
      <Row>
        <Bar
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (tickValue, index, ticks) => {
                    return Number(tickValue).toLocaleString('en-US');
                  },
                },
              },
            },
          }}
        />
      </Row>
    );
  };

  const renderRelacao = (record: LabelValueType) => {
    const data = {
      labels: record?.label,
      datasets: [{
        data: record?.value,
        backgroundColor: ['rgba(226, 107,  10, 0.8)', 'rgba(150, 54, 52, 0.8)'],
        hoverBackgroundColor: ['rgba(226, 107,  10, 1)', 'rgba(150, 54, 52, 1)'],
        hoverBorderColor: 'rgba(234, 236, 244, 0.05)',
        color: 'rgba(255, 255, 255, 1)',
      }],
    };

    return (
      <Row>
        <Bar
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
                color: 'rgba(255, 255, 255, 1)',
                font: {
                  size: 14,
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (tickValue, index, ticks) => {
                    return Number(tickValue).toLocaleString('en-US') + '%';
                  },
                },
              },
            },
          }}
        />
      </Row>
    );
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Row>
          <Col md='6'>
            <Card>
              <Card.Header className={styles.header}>
                <Row>
                  <Col md='10'>
                    Evolução Patrimonial
                  </Col>
                  <Col md='2'>
                    <span id='12M' className={period.evolucao === '12M' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('evolucao', e.currentTarget.id)}>
                      12M
                    </span>
                    &nbsp;
                    &nbsp;
                    <span id='5A' className={period.evolucao === '5A' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('evolucao', e.currentTarget.id)}>
                      5A
                    </span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {renderEvolucao(data?.BuscarMetas.evolucao)}
              </Card.Body>
            </Card>
          </Col>
          <Col md='6'>
            <Card>
              <Card.Header className={styles.header}>
                <Row>
                  <Col md='10'>
                    Rendimento Acumulado
                  </Col>
                  <Col md='2'>
                    <span id='12M' className={period.rendimento === '12M' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('rendimento', e.currentTarget.id)}>
                      12M
                    </span>
                    &nbsp;
                    &nbsp;
                    <span id='5A' className={period.rendimento === '5A' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('rendimento', e.currentTarget.id)}>
                      5A
                    </span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {renderRendimento(data?.BuscarMetas.rendimento)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        &nbsp;
        <Row>
          <Col md='6'>
            <Card>
              <Card.Header className={styles.header}>
                <Row>
                  <Col md='10'>
                    Genho / Perda Mensal
                  </Col>
                  <Col md='2'>
                    <span id='12M' className={period.ganho_perda === '12M' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('ganho_perda', e.currentTarget.id)}>
                      12M
                    </span>
                    &nbsp;
                    &nbsp;
                    <span id='5A' className={period.ganho_perda === '5A' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('ganho_perda', e.currentTarget.id)}>
                      5A
                    </span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {renderGanho(data?.BuscarMetas.ganho_perda)}
              </Card.Body>
            </Card>
          </Col>
          <Col md='6'>
            <Card>
              <Card.Header className={styles.header}>
                <Row>
                  <Col md='10'>
                  Relação Ganho / Perda
                  </Col>
                  <Col md='2'>
                    <span id='12M' className={period.relacao === '12M' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('relacao', e.currentTarget.id)}>
                      12M
                    </span>
                    &nbsp;
                    &nbsp;
                    <span id='5A' className={period.relacao === '5A' ? styles.periodSelected : styles.period} onClick={(e) => handleChange('relacao', e.currentTarget.id)}>
                      5A
                    </span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {renderRelacao(data?.BuscarMetas.relacao)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Spinner show={loading} />
      </Container>
   </Layout>
  );
};

export default ListarMetas;
