import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, ApolloLink, from } from '@apollo/client';
import { Cookies } from 'react-cookie';
import { AES } from 'crypto-js';

const cookies = new Cookies();

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URI,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const key = AES.encrypt(process.env.REACT_APP_API_KEY as string, process.env.REACT_APP_CRYPTO_KEY as string).toString();
  const token = cookies.get('tradecontrol');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-api-key': key,
      'x-security-token': token,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

type PropsType = {
  children: React.ReactNode;
};

export const ApolloManager = (props: PropsType) => {
  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  );
};
