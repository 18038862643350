import { ReactNode } from 'react';
import { useCookies } from 'react-cookie';

import styles from './index.module.scss';
import NavBar from '../NavBar';
import { useLogin } from '../../contexts/LoginContext';

type PropsType = {
  children: ReactNode | ReactNode[];
};

const Layout = ({ children }: PropsType) => {
  const [ cookies ] = useCookies(['tradecontrol']);
  const login = useLogin();

  return (
    <>
      <NavBar
        show={cookies.tradecontrol === undefined ? false : true}
        profile={login.grupo}
      />
      <main className={styles.container}>
        {children}
      </main>
    </>
  );
};

export default Layout;
