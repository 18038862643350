import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Container, Table, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { ResultadoType } from '../../../types/ResultadoType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import { AlertType } from '../../../types/AlertType';
import { formatNumber } from '../../../helpers/Util';
import ContextMenu from '../../../components/ContextMenu';

library.add(fas);

const GET_RESULTADOS = gql`
  query BuscarResultados($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarResultados(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        qtde
        data_entrada
        data_saida
        ativo {
          id
          setor {
            nome
            icone
          }
        }
        preco_compra
        preco_venda
        variacao
        resultado
        acumulado
        status
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const PAGE_SIZE = 10;

const FILTER = {};

const SORT = {
  data_saida: -1,
  id: -1,
};

const ListarResultado = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [resultadoId, setResultadoId] = useState<string | null>(null);
  const [showContext, setShowContext] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowContext(false);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
    setShowContext(false);
  });

  const { data, loading, refetch } = useQuery(GET_RESULTADOS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const resultado: {
    items: ResultadoType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarResultados.values || [],
    page: data?.BuscarResultados.aggregate.page || 1,
    count: data?.BuscarResultados.aggregate.count || 0,
    total: data?.BuscarResultados.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
  };

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      ...FILTER,
      'ativo.id': {
        $regex: `.*${search}.*`,
        $options: 'i',
      },
    };
    refetch({
      filter,
      page: 1,
    });
  };

  const handleContext = (e: any, id: string, status: string) => {
    e.preventDefault();
    setAlert(null);
    setResultadoId(id);
    setPosition({ x: e.pageX, y: e.pageY });
    setShowContext(true && status !== 'INATIVO');
  };
  
  const actionUpdate = () => {
    setShowContext(false);
    navigate(`/resultado/alterar/${resultadoId}`);
  };

  const menuOptions = [
    {name: 'Alterar', disabled: false, action: actionUpdate},
  ];

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th>Setor</th>
              <th>Data Entrada</th>
              <th>Data Saída</th>
              <th>Ativo</th>
              <th>Qtde</th>
              <th>Preço Compra</th>
              <th>Preço Venda</th>
              <th>Variação</th>
              <th>Resultado</th>
              <th>Acumulado</th>
            </tr>
          </thead>
          <tbody>
            {resultado.items.map(item => {
              return (
                <tr key={item.id} className={styles.row} onContextMenu={(event) => handleContext(event, item.id, item.status)}>
                  <td className={styles.iconColumn}>
                    <OverlayTrigger
                      placement='bottom-start'
                      overlay={<Tooltip id='tooltipcircle'>{item.ativo?.setor?.nome}</Tooltip>}
                    >
                      <FontAwesomeIcon icon={item.ativo?.setor?.icone as IconProp} className={styles.icon} size='lg' />
                    </OverlayTrigger>
                  </td>
                  <td className={styles.textColumn}>{item.data_entrada}</td>
                  <td className={styles.textColumn}>{item.data_saida}</td>
                  <td className={styles.textColumn}>{item.ativo.id}</td>
                  <td className={styles.textColumn}>{formatNumber(item.qtde, 0)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.preco_compra, 2)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.preco_venda, 2)}</td>
                  <td className={styles.textColumn}>{item.variacao.toFixed(1)}%</td>
                  <td className={styles.textColumn}>{formatNumber(item.resultado, 0)}</td>
                  <td className={styles.textColumn}>{formatNumber(item.acumulado, 0)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ContextMenu
          options={menuOptions}
          show={showContext && !loading}
          position={position}
        />
        <Pagination
          total={resultado.total}
          page={resultado.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading} />
      </Container>
   </Layout>
  )
};

export default ListarResultado;
