import React from 'react';
import { FormControl, Modal } from 'react-bootstrap';

import styles from './index.module.scss';

type PropsType = {
  show: boolean;
  onSearch: (search: string) => void;
};

const ModalSearch = ({ show, onSearch }: PropsType) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const handleKeyPress = (event: any) => {
    if (['Enter', 'NumpadEnter'].includes(event.code)) {
      onSearch(inputRef.current?.value || '');
    };
  };

  return (
    <Modal centered show={show} animation={false} onHide={() => onSearch(inputRef.current?.value || '')}>
      <Modal.Body className={styles.container} onKeyPress={(e: any) => handleKeyPress(e)}>
        <FormControl type='text' autoFocus ref={inputRef} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalSearch;
