import { useContext } from 'react';
import { createContext, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';

import { ParametroType } from '../../types/ParametroType';

const LIST_PARAMETROS = gql`
  query ListarParametros($filter: JsonValue) {
    ListarParametros(filter: $filter) {
      values {
        id
        data_inicio
        data_fim
        nome
        descricao
        valor_texto
        valor_numero
      }
    }
  }
`;

const FILTER = {
  data_inicio: {
      $lte: moment().format('YYYY-MM-DD'),
  },
  data_fim: {
      $gt: moment().format('YYYY-MM-DD'),
  }
};

const inicialValues = {
  relacaoParcial: 1.00,
  relacaoFinal: 3.00,
};

const ParametroContext = createContext<ParametroType>(inicialValues);

const useParametro = () => {
  const context = useContext(ParametroContext);

  const [listParametros] = useLazyQuery(LIST_PARAMETROS, {
    variables: {
      filter: FILTER,
    },
    onCompleted: (res) => {
      const values = res.ListarParametros.values;
      const parcial = values.find((item: any) => {
        return item.nome === 'RELACAO_RG_PARCIAL';
      });
      context.relacaoParcial = parcial?.valor_numero || 1.00;
      const final = values.find((item: any) => {
        return item.nome === 'RELACAO_RG_FINAL';
      });
      context.relacaoFinal = final?.valor_numero || 3.00;
    },
    onError: (err) => {
      context.relacaoParcial = 1.00;
      context.relacaoFinal = 3.00;
    }
  });

  useEffect(() => {
    listParametros();
  }, [listParametros]);

  return context;
};

export { useParametro };
