import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import styles from './index.module.scss';
import Layout from '../../components/Layout';

const NoMatch = () => {
  return (
    <Layout>
        <Card className={styles.card}>
          <Card.Body>
            <div className={styles.iconRow}>
              <FontAwesomeIcon className={styles.icon} icon={faTriangleExclamation} size='4x'/>
            </div>
            Página não encontrada.
          </Card.Body>
        </Card>
    </Layout>
  );
};

export default NoMatch;
