import { Dispatch, SetStateAction } from 'react';
import { Button, Modal } from 'react-bootstrap';

import styles from './index.module.scss';

type PropsType = {
  title: string;
  show: boolean;
  onNo: Dispatch<SetStateAction<boolean>>;
  onYes: () => void;
};

const ModalYesNo = ({ title, show, onNo, onYes }: PropsType) => {
  const handleClose = () => {
    onNo(false);
  };

  return ( 
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton className={styles.header} closeVariant='white'>
        <Modal.Title>TradeControl</Modal.Title>
      </Modal.Header>
      <Modal.Body>{title}</Modal.Body>
      <Modal.Footer>
        <Button variant='outline-primary' onClick={onYes}>Sim</Button>
        <Button variant='outline-primary' onClick={handleClose}>Não</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalYesNo;
