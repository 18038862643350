import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Container, Alert, Card, Row, Col, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik';
import * as yup from 'yup';
import crypto from 'crypto-js';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import Spinner from '../../../components/Spinner';
import { AlertType } from '../../../types/AlertType';

library.add(fas);

const SCRIPT_ACCUMULATE = gql`
  mutation ScriptAcumular($input: AcumularInput!) {
    ScriptAcumular(input: $input) {
      code
      message
      stack
    }
  }
`;

const SCRIPT_EXPORT = gql`
  mutation ScriptExportar($input: ColecaoInput!) {
    ScriptExportar(input: $input) {
      code
      message
      stack
    }
  }
`;

const SCRIPT_IMPORT = gql`
  mutation ScriptImportar($input: ColecaoInput!) {
    ScriptImportar(input: $input) {
      code
      message
      stack
    }
  }
`;

const acumular = {
  patrimonio: false,
  performance: false,
  resultado: false,
};

const collection = {
  analise: false,
  ativo: false,
  corretagem: false,
  imposto: false,
  login: false,
  parametro: false,
  patrimonio: false,
  performance: false,
  posicao: false,
  processo: false,
  resultado: false,
  setor: false,
};

const ExecutarScript = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isRunning, setIsRunning] = useState(false);

  //#region Accumulate
  const [accumulate] = useMutation(SCRIPT_ACCUMULATE, {
    onCompleted: (res) => {
      setIsRunning(false);
      setAlert({ message: res.ScriptAcumular.message, variant: 'success' });
    },
    onError: (err) => {
      setIsRunning(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const handleAccumulate = () => {
    setAlert(null);
    let found = false;
    Object.keys(acumular).forEach((key) => {
      found = found || (acumular as any)[key];
    });
    if (found === false) {
      setAlert({ message: 'Selecione um item para acumular', variant: 'danger' });
      return;
    };
    setIsRunning(true);
    accumulate({
      variables: {
        input: {
          ...acumular,
        },
      },
    });
  };

  const renderAccumulate = () => {
    return (
      <Card className='shadow'>
        <Card.Header className={styles.cardHeader}>Acumular</Card.Header>
        <Card.Body>
          <Row className={styles.row}>
            <Form.Check
              type='switch'
              value='patrimonio'
              label='Patrimônio'
              id='acc-patrimonio'
              onChange={e => acumular.patrimonio = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='performance'
              label='Performance'
              id='acc-performance'
              onChange={e => acumular.performance = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='resultado'
              label='Resultado'
              id='acc-resultado'
              onChange={e => acumular.resultado = e.target.checked}
              disabled={isRunning}
            />
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row className='text-center'>
            <Col md='12'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Executar</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='play'
                  size='lg'
                  className={isRunning ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={handleAccumulate}
                />
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  };
  //#endregion Accumulate

  //#region Export/Import
  const [exportar] = useMutation(SCRIPT_EXPORT, {
    onCompleted: (res) => {
      setIsRunning(false);
      setAlert({ message: res.ScriptExportar.message, variant: 'success' });
    },
    onError: (err) => {
      setIsRunning(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [importar] = useMutation(SCRIPT_IMPORT, {
    onCompleted: (res) => {
      setIsRunning(false);
      setAlert({ message: res.ScriptImportar.message, variant: 'success' });
    },
    onError: (err) => {
      setIsRunning(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const handleExport = () => {
    setAlert(null);
    let found = false;
    Object.keys(collection).forEach((key) => {
      found = found || (collection as any)[key];
    });
    if (found === false) {
      setAlert({ message: 'Selecione uma coleção para exportar', variant: 'danger' });
      return;
    };
    setIsRunning(true);
    exportar({
      variables: {
        input: {
          ...collection,
        },
      },
    });
  };

  const handleImport = () => {
    setAlert(null);
    let found = false;
    Object.keys(collection).forEach((key) => {
      found = found || (collection as any)[key];
    });
    if (found === false) {
      setAlert({ message: 'Selecione uma coleção para importar', variant: 'danger' });
      return;
    };
    setIsRunning(true);
    importar({
      variables: {
        input: {
          ...collection,
        },
      },
    });
  };

  const renderCollection = () => {
    return (
      <Card className='shadow'>
        <Card.Header className={styles.cardHeader}>Coleção</Card.Header>
        <Card.Body>
          <Row className={styles.row}>
            <Form.Check
              type='switch'
              value='analise'
              label='Análise'
              id='col-analise'
              onChange={e => collection.analise = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='ativo'
              label='Ativo'
              id='col-ativo'
              onChange={e => collection.ativo = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='corretagem'
              label='Corretagem'
              id='col-corretagem'
              onChange={e => collection.corretagem = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='imposto'
              label='Imposto'
              id='col-imposto'
              onChange={e => collection.imposto = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='login'
              label='Login'
              id='col-login'
              onChange={e => collection.login = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='parametro'
              label='Parâmetro'
              id='col-parametro'
              onChange={e => collection.parametro = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='patrimonio'
              label='Patrimônio'
              id='col-patrimonio'
              onChange={e => collection.patrimonio = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='performance'
              label='Performance'
              id='col-performance'
              onChange={e => collection.performance = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='posicao'
              label='Posição'
              id='col-posicao'
              onChange={e => collection.posicao = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='processo'
              label='Processo'
              id='col-processo'
              onChange={e => collection.processo = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='resultado'
              label='Resultado'
              id='col-resultado'
              onChange={e => collection.resultado = e.target.checked}
              disabled={isRunning}
            />
            <Form.Check
              type='switch'
              value='setor'
              label='Setor'
              id='col-setor'
              onChange={e => collection.setor = e.target.checked}
              disabled={isRunning}
            />
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row className='text-center'>
            <Col md='6'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Exportar</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='file-export'
                  size='lg'
                  className={isRunning ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={handleExport}
                />
              </OverlayTrigger>
            </Col>
            <Col md='6'>
              <OverlayTrigger
                placement='bottom-start'
                overlay={<Tooltip id='tooltipcircle'>Importar</Tooltip>}
              >
                <FontAwesomeIcon
                  icon='file-import'
                  size='lg'
                  className={isRunning ? styles.pointerDisabled : styles.pointerEnabled}
                  onClick={handleImport}
                />
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  };
  //#endregion Export/Import

  //#region Encode
  const formik = useFormik({
    validationSchema: yup.object({
      plain: yup.string().required().trim(),
      key: yup.string().required().trim(),
      encode: yup.string(),
    }),
    initialValues: {
      plain: '',
      key: '',
      encode: '',
    },
    onSubmit: values => {
      setAlert(null);
      setIsRunning(true);
      const cipher = crypto.AES.encrypt(values.plain, values.key);
      values.encode = cipher.toString();
      setIsRunning(false);
    },
  });

  const renderEncode = () => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Card className='shadow'>
          <Card.Header className={styles.cardHeader}>Codificar</Card.Header>
          <Card.Body>
            <Row className={styles.row}>
              <Form.Control
                type='text'
                name='plain'
                placeholder='texto'
                value={formik.values.plain}
                onChange={e => {
                  formik.values.encode = '';
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                isValid={formik.touched.plain && !formik.errors.plain}
                isInvalid={formik.touched.plain && formik.errors.plain !== undefined}
                disabled={isRunning}
              />
            </Row>
            <Row className={styles.row}>
              <Form.Control
                type='text'
                name='key'
                placeholder='key'
                value={formik.values.key}
                onChange={e => {
                  formik.values.encode = '';
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                isValid={formik.touched.key && !formik.errors.key}
                isInvalid={formik.touched.key && formik.errors.key !== undefined}
                disabled={isRunning}
              />
            </Row>
            <Row className={styles.row}>
              <Form.Control
                as='textarea'
                rows={2}
                name='encode'
                placeholder='codificado'
                value={formik.values.encode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isRunning}
                readOnly
              />
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row className='text-center'>
              <Col md='6'>
                <OverlayTrigger
                  placement='bottom-start'
                  overlay={<Tooltip id='tooltipcircle'>Executar</Tooltip>}
                >
                  <FontAwesomeIcon
                    icon='play'
                    size='lg'
                    className={isRunning ? styles.pointerDisabled : styles.pointerEnabled}
                    onClick={formik.submitForm}
                  />
                </OverlayTrigger>
              </Col>
              <Col md='6'>
                <OverlayTrigger
                  placement='bottom-start'
                  overlay={<Tooltip id='tooltipcircle'>Limpar</Tooltip>}
                >
                  <FontAwesomeIcon
                    icon='eraser'
                    size='lg'
                    className={isRunning ? styles.pointerDisabled : styles.pointerEnabled}
                    onClick={e => formik.resetForm()}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Form>
    );
  };
  //#endregion Encode

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Row>
          <Col md='12'>
            <Card>
              <Card.Header className={styles.header}>Scripts</Card.Header>
              <Card.Body>
                <Row>
                  <Col md='4'>
                    {renderAccumulate()}
                  </Col>
                  <Col md='4'>
                    {renderCollection()}
                  </Col>
                  <Col md='4'>
                    {renderEncode()}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Spinner show={isRunning} />
      </Container>
   </Layout>
  )
};

export default ExecutarScript;
