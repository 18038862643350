import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Container, Alert, Card, Row, Col } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend, Tooltip, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Filler } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import Spinner from '../../../components/Spinner';
import { AlertType } from '../../../types/AlertType';
import { LabelValueType } from '../../../types/PainelType';

const GET_PERFORMANCE = gql`
  query BuscarPerformance {
    BuscarPerformance {
      ibov {
        label
        value
      }
      carteira {
        label
        value
      }
    }
  }
`;

ChartJS.register(ArcElement, Legend, ChartDataLabels, Tooltip, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Filler);

const ListarPerformance = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);

  const { data, loading } = useQuery(GET_PERFORMANCE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const renderPerformance = (ibov: LabelValueType, carteira: LabelValueType) => {
    const data = {
      labels: ibov?.label,
      datasets: [{
        label: 'ibov',
        data: ibov?.value,
        lineTension: 0.3,
        backgroundColor: 'rgba(226, 107, 10, 0.1)',
        borderColor: 'rgba(226, 107, 10, 1)',
        pointRadius: 3,
        pointBackgroundColor: 'rgba(226, 107, 10, 1)',
        pointBorderColor: 'rgba(226, 107, 10, 1)',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'rgba(226, 107, 10, 1)',
        pointHoverBorderColor: 'rgba(226, 107, 10, 1)',
        pointHitRadius: 10,
        pointBorderWidth: 2,
        fill: true,
      },
      {
        label: 'carteira',
        data: carteira?.value,
        lineTension: 0.3,
        backgroundColor: 'rgba(150, 54, 52, 0.1)',
        borderColor: 'rgba(150, 54, 52, 1)',
        pointRadius: 3,
        pointBackgroundColor: 'rgba(150, 54, 52, 1)',
        pointBorderColor: 'rgba(150, 54, 52, 1)',
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'rgba(150, 54, 52, 1)',
        pointHoverBorderColor: 'rgba(150, 54, 52, 1)',
        pointHitRadius: 10,
        pointBorderWidth: 2,
        fill: true,
      }],
    };

    return (
      <Row>
        <Line
          height={'100%'}
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: true,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (tickValue, index, ticks) => {
                    return Number(tickValue).toLocaleString('en-US') + '%';
                  },
                },
              },
            },
          }}
        />
      </Row>
    );
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Row>
          <Col md='12'>
            <Card>
              <Card.Header className={styles.header}>Performance da Carteira</Card.Header>
              <Card.Body>
                {renderPerformance(data?.BuscarPerformance.ibov, data?.BuscarPerformance.carteira)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Spinner show={loading} />
      </Container>
   </Layout>
  );
};

export default ListarPerformance;
