import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Alert, Row, Card, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { AlertType } from '../../../types/AlertType';
import Spinner from '../../../components/Spinner';
import { REGEX_ATIVO, REGEX_DATA } from '../../../helpers/Validation';
import { calcISS } from '../../../helpers/Util';

const GET_CORRETAGEM = gql`
  query BuscarCorretagem($id: String!) {
    BuscarCorretagem(id: $id) {
      id
      tipo
      data
      ativo {
        id
      }
      qtde
      preco
      liquidacao
      emolumentos
      taxa
      iss
      custo
      imposto
    }
  }
`;

const UPDATE_CORRETAGEM = gql`
  mutation AlterarCorretagem($id: String!, $input: CorretagemInput!) {
    AlterarCorretagem(id: $id, input: $input) {
      code
      message
      stack
    }
  }
`;

const AlterarCorretagem = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams<{ id: string }>();

  const { loading } = useQuery(GET_CORRETAGEM, {
    variables: {
      id: params?.id,
    },
    onCompleted: (res) => {
      formik.setValues({
        tipo: res.BuscarCorretagem.tipo,
        data: res.BuscarCorretagem.data,
        ativo: res.BuscarCorretagem.ativo.id,
        qtde: res.BuscarCorretagem.qtde,
        preco: res.BuscarCorretagem.preco.toFixed(2),
        liquidacao: res.BuscarCorretagem.liquidacao.toFixed(2),
        emolumentos: res.BuscarCorretagem.emolumentos.toFixed(2),
        taxa: res.BuscarCorretagem.taxa.toFixed(2),
        iss: res.BuscarCorretagem.iss.toFixed(2),
        custo: res.BuscarCorretagem.custo.toFixed(2),
        imposto: res.BuscarCorretagem.imposto ? res.BuscarCorretagem.imposto.toFixed(2) : '0.00',
      });
    },
    onError: (err) => {
      setIsDisabled(true);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [updateCorretagem, { loading: updating }] = useMutation(UPDATE_CORRETAGEM, {
    onCompleted: (res) => {
      setAlert({ message: res.AlterarCorretagem.message, variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      tipo: yup.string().required(),
      data: yup.string().required().matches(REGEX_DATA),
      ativo: yup.string().required().matches(REGEX_ATIVO),
      qtde: yup.number().positive().required(),
      preco: yup.number().positive().required(),
      liquidacao: yup.number().positive().required(),
      emolumentos: yup.number().positive().required(),
      taxa: yup.number().positive().required(),
      iss: yup.number().positive().required(),
      custo: yup.number().positive().required(),
      imposto: yup.number().when('tipo', {
        is: 'VENDA',
        then: yup.number().positive().required(),
        otherwise: yup.number().optional(),
      }),
    }),
    initialValues: {
      tipo: 'COMPRA',
      data: '',
      ativo: '',
      qtde: '',
      preco: '',
      liquidacao: '',
      emolumentos: '',
      taxa: '',
      iss: '',
      custo: '',
      imposto: '',
    },
    onSubmit: values => {
      setAlert(null);
      updateCorretagem({
        variables: {
          id: params?.id,
          input: {
            data: values.data,
            liquidacao: Number(values.liquidacao),
            emolumentos: Number(values.emolumentos),
            taxa: Number(values.taxa),
            iss: Number(values.iss),
            custo: Number(values.custo),
            imposto: Number(values?.imposto) || null,
          },
        },
      });
    },
  });

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className={styles.header}>Alterar Corretagem</Card.Header>
                <Card.Body>
                  <fieldset disabled={isDisabled || loading || updating}>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='data'>
                          <Form.Label>{formik.values.tipo === 'COMPRA' ? 'Data Entrada:' : 'Data Saída:'}</Form.Label>
                          <Form.Control
                            type='text'
                            name='data'
                            placeholder='aaaa-mm-dd'
                            value={formik.values.data}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.touched.data && !formik.errors.data}
                            isInvalid={formik.touched.data && formik.errors.data !== undefined}
                            maxLength={10}
                            autoFocus
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='tipo'>
                          <Form.Label>Tipo:</Form.Label>
                          <Form.Select
                            name='tipo'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.tipo}
                            disabled
                          >
                            <option value='COMPRA'>Compra</option>
                            <option value='VENDA'>Venda</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='ativo'>
                          <Form.Label>Ativo:</Form.Label>
                          <Form.Control
                            type='text'
                            name='ativo'
                            placeholder='ativo'
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='qtde'>
                          <Form.Label>Qtde:</Form.Label>
                          <Form.Control
                            type='text'
                            name='qtde'
                            placeholder='0'
                            value={formik.values.qtde}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='preco'>
                          <Form.Label>Preço:</Form.Label>
                          <Form.Control
                            type='text'
                            name='qtde'
                            placeholder='0.00'
                            value={formik.values.preco}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='taxa'>
                          <Form.Label>Taxa:</Form.Label>
                          <Form.Control
                            type='text'
                            name='taxa'
                            placeholder='0.00'
                            value={formik.values.taxa}
                            onChange={formik.handleChange}
                            onBlur={e => {
                              formik.handleBlur(e);
                              formik.values.iss = calcISS(Number(formik.values.taxa), formik.values.data).toFixed(2);
                              formik.values.custo = (Number(formik.values.liquidacao) + Number(formik.values.emolumentos) + Number(formik.values.taxa) + Number(formik.values.iss)).toFixed(2);
                            }}
                            isValid={formik.touched.taxa && !formik.errors.taxa}
                            isInvalid={formik.touched.taxa && formik.errors.taxa !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='liquidacao'>
                          <Form.Label>Liquidação:</Form.Label>
                          <Form.Control
                            type='text'
                            name='liquidacao'
                            placeholder='0.00'
                            value={formik.values.liquidacao}
                            onChange={formik.handleChange}
                            onBlur={e => {
                              formik.handleBlur(e);
                              formik.values.custo = (Number(formik.values.liquidacao) + Number(formik.values.emolumentos) + Number(formik.values.taxa) + Number(formik.values.iss)).toFixed(2);
                            }}
                            isValid={formik.touched.liquidacao && !formik.errors.liquidacao}
                            isInvalid={formik.touched.liquidacao && formik.errors.liquidacao !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='emolumentos'>
                          <Form.Label>Emolumentos:</Form.Label>
                          <Form.Control
                            type='text'
                            name='emolumentos'
                            placeholder='0.00'
                            value={formik.values.emolumentos}
                            onChange={formik.handleChange}
                            onBlur={e => {
                              formik.handleBlur(e);
                              formik.values.custo = (Number(formik.values.liquidacao) + Number(formik.values.emolumentos) + Number(formik.values.taxa) + Number(formik.values.iss)).toFixed(2);
                            }}
                            isValid={formik.touched.emolumentos && !formik.errors.emolumentos}
                            isInvalid={formik.touched.emolumentos && formik.errors.emolumentos !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='iss'>
                          <Form.Label>ISS:</Form.Label>
                          <Form.Control
                            type='text'
                            name='iss'
                            placeholder='0.00'
                            value={formik.values.iss}
                            onChange={formik.handleChange}
                            onBlur={e => {
                              formik.handleBlur(e);
                              formik.values.custo = (Number(formik.values.liquidacao) + Number(formik.values.emolumentos) + Number(formik.values.taxa) + Number(formik.values.iss)).toFixed(2);
                            }}
                            isValid={formik.touched.iss && !formik.errors.iss}
                            isInvalid={formik.touched.iss && formik.errors.iss !== undefined}
                            maxLength={11}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='custo'>
                          <Form.Label>Custo:</Form.Label>
                          <Form.Control
                            type='text'
                            name='custo'
                            placeholder='0.00'
                            value={formik.values.custo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md='2' />
                      <Col md='2'>
                        <Form.Group className='mb-4' controlId='imposto'>
                          <Form.Label>Imposto de Renda:</Form.Label>
                          <Form.Control
                            type='text'
                            name='imposto'
                            placeholder='0.00'
                            value={formik.values.imposto}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={formik.values.tipo === 'VENDA' && formik.touched.imposto && !formik.errors.imposto}
                            isInvalid={formik.values.tipo === 'VENDA' && formik.touched.imposto && formik.errors.imposto !== undefined}
                            maxLength={11}
                            disabled={formik.values.tipo === 'COMPRA'}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          &nbsp;
          <div className={styles.buttonContainer}>
            <Button variant='outline-primary' type='submit' disabled={isDisabled || loading || updating}>Alterar</Button>
            <Button variant='outline-primary' disabled>Limpar</Button>
          </div>
        </Form>
        <Spinner show={loading || updating} />
      </Container>
    </Layout>
  );
}

export default AlterarCorretagem;
