import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Alert, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCookies } from 'react-cookie';
import { AES } from 'crypto-js';

import styles from './index.module.scss';
import Layout from '../../components/Layout';
import { AlertType } from '../../types/AlertType';

const AUTH = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      type,
      token,
      expired,
    }
  }
`;

const VERIFY = gql`
  mutation Verify($input: VerifyInput!) {
    verify(input: $input) {
      type,
      token,
      expired,
    }
  }
`;

const GENERATE = gql`
  mutation Generate($input: VerifyInput!) {
    generate(input: $input) {
      type,
      token,
      expired,
    }
  }
`;

const Login = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['tradecontrol'])
  const [alert, setAlert] = useState<AlertType | null>(null);
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (cookies?.tradecontrol) {
      navigate('/posicao/listar');
      return;
    };  
  });

  //#region Authentication
  const [authenticate, { loading: authenticating }] = useMutation(AUTH, {
    onCompleted: (res) => {
      if (res.auth.type === 'Bearer') {
        setCookie('tradecontrol', res.auth.token, { path: '/', maxAge: res.auth.expired });
        navigate('/posicao/listar');
      } else {
        setToken(res.auth.token);
      };
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formikAuth = useFormik({
    validationSchema: yup.object({
      login: yup.string().email().required('Teste'),
      senha: yup.string().required(),
    }),
    initialValues: {
      login: '',
      senha: '',
    },
    onSubmit: values => {
      const cipher = AES.encrypt(values.senha, process.env.REACT_APP_CRYPTO_KEY as string);
      authenticate({
        variables: {
          input: {
            login: values.login,
            senha: cipher.toString(),
          },
        },
      });
    },
  });
  
  const renderAuth = () => {
    return (
      <Card className={styles.card}>
        <Card.Body>
          <Alert
            show={alert !== null}
            variant={alert?.variant}
          >
            {alert?.message}
          </Alert>
          <fieldset disabled={authenticating}>
            <Form onSubmit={formikAuth.handleSubmit}>
              <Form.Group className='mb-4' controlId='login'>
                <Form.Label>Login</Form.Label>
                <Form.Control
                  type='login'
                  placeholder='login'
                  value={formikAuth.values.login}
                  onChange={formikAuth.handleChange}
                  onBlur={formikAuth.handleBlur}
                  isValid={formikAuth.touched.login && !formikAuth.errors.login}
                  isInvalid={formikAuth.touched.login && formikAuth.errors.login !== undefined}
                  autoFocus
                />
              </Form.Group>
              <Form.Group controlId='senha'>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='senha'
                  value={formikAuth.values.senha}
                  onChange={formikAuth.handleChange}
                  onBlur={formikAuth.handleBlur}
                  isValid={formikAuth.touched.senha && !formikAuth.errors.senha}
                  isInvalid={formikAuth.touched.senha && formikAuth.errors.senha !== undefined}
                />
              </Form.Group>
              <div className={styles.buttonRow}>
                <Button className={styles.button} variant='outline-primary' type='submit'>Entrar</Button>
              </div>
            </Form>
          </fieldset>
        </Card.Body>
      </Card>
    );
  };
  //#endregion Authentication

  //#region Code Validation and Generation
  const [verify, { loading: verifying }] = useMutation(VERIFY, {
    onCompleted: (res) => {
      setCookie('tradecontrol', res.verify.token, { path: '/', maxAge: res.verify.expired });
      navigate('/posicao/listar');
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [generate, { loading: generating }] = useMutation(GENERATE, {
    onCompleted: (res) => {
      setToken(res.generate.token);
      setAlert({ message: 'Código gerado com sucesso', variant: 'success' });
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const formikVerify = useFormik({
    validationSchema: yup.object({
      codigo01: yup.string().required(),
      codigo02: yup.string().required(),
      codigo03: yup.string().required(),
      codigo04: yup.string().required(),
      codigo05: yup.string().required(),
      codigo06: yup.string().required(),
    }),
    initialValues: {
      codigo01: '',
      codigo02: '',
      codigo03: '',
      codigo04: '',
      codigo05: '',
      codigo06: '',
    },
    onSubmit: values => {
      const codigo = `${values.codigo01}${values.codigo02}${values.codigo03}${values.codigo04}${values.codigo05}${values.codigo06}`;
      verify({
        variables: {
          input: {
            token,
            codigo,
          },
        },
      });
    },
  });

  const handleGenerate = () => {
    formikVerify.resetForm();
    setAlert(null);
    generate({
      variables: {
        input: {
          token,
        },
      },
    });
  };

  const renderVerify = () => {
    return (
      <Card className={styles.card}>
        <Card.Body>
          <Alert
            show={alert !== null}
            variant={alert?.variant}
          >
            {alert?.message}
          </Alert>
          <fieldset disabled={verifying || generating}>
            <Form onSubmit={formikVerify.handleSubmit}>
              <Row className='mb-6'>
                <h5 className='text-center'>Digite o código de validação:</h5>
              </Row>
              &nbsp;
              <Row className='mb-6'>
                <Form.Group as={Col} controlId='codigo01'>
                  <Form.Control
                    className='text-center'
                    type='text'
                    name='codigo01'
                    value={formikVerify.values.codigo01}
                    onChange={formikVerify.handleChange}
                    onBlur={formikVerify.handleBlur}
                    maxLength={1}
                    autoFocus
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='codigo02'>
                  <Form.Control
                    className='text-center'
                    type='text'
                    name='codigo02'
                    value={formikVerify.values.codigo02}
                    onChange={formikVerify.handleChange}
                    onBlur={formikVerify.handleBlur}
                    maxLength={1}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='codigo03'>
                  <Form.Control
                    className='text-center'
                    type='text'
                    name='codigo03'
                    value={formikVerify.values.codigo03}
                    onChange={formikVerify.handleChange}
                    onBlur={formikVerify.handleBlur}
                    maxLength={1}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='codigo04'>
                  <Form.Control
                    className='text-center'
                    type='text'
                    name='codigo04'
                    value={formikVerify.values.codigo04}
                    onChange={formikVerify.handleChange}
                    onBlur={formikVerify.handleBlur}
                    maxLength={1}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='codigo05'>
                  <Form.Control
                    className='text-center'
                    type='text'
                    name='codigo05'
                    value={formikVerify.values.codigo05}
                    onChange={formikVerify.handleChange}
                    onBlur={formikVerify.handleBlur}
                    maxLength={1}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='codigo06'>
                  <Form.Control
                    className='text-center'
                    type='text'
                    name='codigo06'
                    value={formikVerify.values.codigo06}
                    onChange={formikVerify.handleChange}
                    onBlur={e => {
                      formikVerify.handleBlur(e);
                      formikVerify.submitForm();
                    }}
                    maxLength={1}
                  />
                </Form.Group>
              </Row>
              <div className={styles.buttonRow}>
                <Button className={styles.button} variant='outline-primary' onClick={handleGenerate}>Novo código</Button>
              </div>
            </Form>
          </fieldset>
        </Card.Body>
      </Card>
    );
  };
  //#region Code Validation and Generation

  return (
    <Layout>
      {token === null && renderAuth()}
      {token !== null && renderVerify()}
    </Layout>
  );
};

export default Login;
