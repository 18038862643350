import { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Container, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import { ProcessoType } from '../../../types/ProcessoType';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import ModalSearch from '../../../components/ModalSearch';
import { AlertType } from '../../../types/AlertType';

library.add(fas);

const GET_PROCESSOS = gql`
  query BuscarProcessos($filter: JsonValue, $page: Int, $limit: Int, $sort: JsonValue) {
    BuscarProcessos(filter: $filter, page: $page, limit: $limit, sort: $sort) {
      values {
        id
        nome
        descricao
        executed
        status
      }
      aggregate {
        page
        count
        total
      }
    }
  }
`;

const UPDATE_COTACAO = gql`
  mutation AtualizarCotacao {
    AtualizarCotacao {
      code
      message
      stack
    }
  }
`;

const ACCUMULATE_PERFORMANCE = gql`
  mutation AcumularPerformance {
    AcumularPerformance {
      code
      message
      stack
    }
  }
`;

const CALCULATE_IMPOSTO = gql`
  mutation CalcularImposto {
    CalcularImposto {
      code
      message
      stack
    }
  }
`;

const PAGE_SIZE = 10;

const FILTER = {};

const SORT = {
  executed: -1,
};

const ListarProcesso = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  window.addEventListener('keyup', (event) => {
    if (event.code === 'Space') {
      setAlert(null);
      setShowSearch(true);
    };
  });

  window.addEventListener('click', (event) => {
    setShowSearch(false);
  });

  const { data, loading, refetch } = useQuery(GET_PROCESSOS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      filter: FILTER,
      page: 1,
      limit: PAGE_SIZE,
      sort: SORT,
    },
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [updateCotacao] = useMutation(UPDATE_COTACAO, {
    onCompleted: (res) => {
      setIsUpdating(false);
      refetch();
      setAlert({ message: res.AtualizarCotacao.message, variant: 'success' });
    },
    onError: (err) => {
      setIsUpdating(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [accumulatePerformance] = useMutation(ACCUMULATE_PERFORMANCE, {
    onCompleted: (res) => {
      setIsUpdating(false);
      refetch();
      setAlert({ message: res.AcumularPerformance.message, variant: 'success' });
    },
    onError: (err) => {
      setIsUpdating(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const [calculateImposto] = useMutation(CALCULATE_IMPOSTO, {
    onCompleted: (res) => {
      setIsUpdating(false);
      refetch();
      setAlert({ message: res.CalcularImposto.message, variant: 'success' });
    },
    onError: (err) => {
      setIsUpdating(false);
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const processo: {
    items: ProcessoType[];
    page: number;
    count: number;
    total: number
  } = {
    items: data?.BuscarProcessos.values || [],
    page: data?.BuscarProcessos.aggregate.page || 1,
    count: data?.BuscarProcessos.aggregate.count || 0,
    total: data?.BuscarProcessos.aggregate.total || 0,
  };

  const handlePagination = (page: number) => {
    setAlert(null);
    refetch({
      page,
    });
  };

  const handleSearch = (search: string) => {
    setShowSearch(false);
    const filter = {
      ...FILTER,
      nome: {
        $regex: `.*${search}.*`,
        $options: 'i',
      },
    };
    refetch({
      filter,
    });
  };

  const handleProcesso = (nome: string) => {
    setAlert(null);
    setIsUpdating(true);
    if (nome === 'UpdateCotacao') updateCotacao();
    else if (nome === 'AccumulatePerformance') accumulatePerformance();
    else if (nome === 'CalculateImposto') calculateImposto();
    else setIsUpdating(false);
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Table className={styles.table}>
          <thead className={styles.header}>
            <tr>
              <th className={styles.headerColumn}>Descrição</th>
              <th className={styles.executedColumn}>Atualizado Em</th>
              <th className={styles.buttonColumn}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {processo.items.map(item => {
              return (
                <tr key={item.id} className={styles.row}>
                  <td className={styles.textColumn}>{item.descricao}</td>
                  <td className={styles.executedColumn}>{item.executed ? moment(item.executed).format('YYYY-MM-DD HH:mm') : '-'}</td>
                  <td className={styles.iconColumn}>
                    <FontAwesomeIcon
                      icon='arrows-rotate'
                      size='lg'
                      className={item.status === 'INATIVO' || isUpdating ? styles.pointerDisabled : styles.pointerEnabled}
                      onClick={() => handleProcesso(item.nome)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Pagination
          total={processo.total}
          page={processo.page}
          size={PAGE_SIZE}
          onPaginate={handlePagination}
        />
        <ModalSearch
          show={showSearch}
          onSearch={handleSearch}
        />
        <Spinner show={loading || isUpdating} />
      </Container>
   </Layout>
  )
};

export default ListarProcesso;
