import { Container, Nav, Navbar, NavDropdown, Image } from 'react-bootstrap';

import styles from './index.module.scss';

type PropsType = {
  show: boolean;
  profile: string;
};

const NavBar = ({ show, profile }: PropsType) => {
  if (!show) {
    return (
      <Navbar bg='white' expand='lg'>
        <Container className={styles.container}>
        <Navbar.Brand href='/logout'>
          <Image className={styles.image} src={require('../../assets/Trade.png')} />
        </Navbar.Brand>
        </Container>
      </Navbar>
    );
  };

  return (
    <Navbar bg='white' expand='lg'>
      <Container className={styles.container}>
        <Navbar.Brand href='/logout'>
          <Image className={styles.image} src={require('../../assets/Trade.png')} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='trade-nav' />
        <Navbar.Collapse id='trade-nav'>
          <Nav className='me-auto'>
            <NavDropdown title='Análise' id='trade-nav-analise'>
              <NavDropdown.Item href='/analise/listar'>Listar</NavDropdown.Item>
              <NavDropdown.Item href='/analise/cadastrar'>Cadastrar</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Posição' id='trade-nav-posicao'>
              <NavDropdown.Item href='/posicao/listar'>Listar</NavDropdown.Item>
              <NavDropdown.Item href='/posicao/cadastrar'>Cadastrar</NavDropdown.Item>
              <NavDropdown.Item href='/posicao/historico'>Histórico</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Financeiro' id='trade-nav-financeiro'>
              <NavDropdown.Item href='/corretagem/listar'>Corretagem</NavDropdown.Item>
              <NavDropdown.Item href='/imposto/listar'>Imposto de Renda</NavDropdown.Item>
              <NavDropdown.Item href='/resultado/listar'>Resultado</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Ativo' id='trade-nav-ativo'>
              <NavDropdown.Item href='/ativo/listar'>Listar</NavDropdown.Item>
              <NavDropdown.Item href='/ativo/cadastrar'>Cadastrar</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Setor' id='trade-nav-setor'>
              <NavDropdown.Item href='/setor/listar'>Listar</NavDropdown.Item>
              <NavDropdown.Item href='/setor/cadastrar'>Cadastrar</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Painel' id='trade-nav-painel'>
              <NavDropdown.Item href='/painel/resumo'>Resumo</NavDropdown.Item>
              <NavDropdown.Item href='/painel/metas'>Metas</NavDropdown.Item>
              <NavDropdown.Item href='/painel/performance'>Performance</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Patrimônio' id='trade-nav-patrimonio'>
              <NavDropdown.Item href='/patrimonio/listar'>Listar</NavDropdown.Item>
              <NavDropdown.Item href='/patrimonio/cadastrar'>Cadastrar</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='Admin' id='trade-nav-admin'>
              <NavDropdown.Item href='/admin/processo'>Processos</NavDropdown.Item>
              <NavDropdown.Item href='/admin/script'>Scripts</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
