import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Container, Alert, Card, Row, Col } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import styles from './index.module.scss';
import Layout from '../../../components/Layout';
import Spinner from '../../../components/Spinner';
import { AlertType } from '../../../types/AlertType';
import { formatNumber } from '../../../helpers/Util';

const GET_RESUMO = gql`
  query BuscarResumo {
    BuscarResumo {
      gerenciamento {
        exposicao_total
        ordens_executadas
        resultado
        stop_loss
        stop_gain
        capital_total
      }
      consolidado {
        total_investido
        saldo
      }
      setor {
        label
        value
      }
    }
  }
`;

ChartJS.register(ArcElement, Legend, ChartDataLabels, Tooltip);

const ListarResumo = () => {
  const [alert, setAlert] = useState<AlertType | null>(null);

  const { data, loading } = useQuery(GET_RESUMO, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onError: (err) => {
      setAlert({ message: err.message, variant: 'danger' });
    },
  });

  const gerenciamento = {
    exposicao_total: data?.BuscarResumo.gerenciamento.exposicao_total ?? 0,
    ordens_executadas: data?.BuscarResumo.gerenciamento.ordens_executadas ?? 0,
    resultado: data?.BuscarResumo.gerenciamento.resultado ?? 0,
    stop_loss: data?.BuscarResumo.gerenciamento.stop_loss ?? 0,
    stop_gain: data?.BuscarResumo.gerenciamento.stop_gain ?? 0,
    capital_total: data?.BuscarResumo.gerenciamento.capital_total ?? 0,
  };

  const posicao = {
    labels: ['Total Investido', 'Saldo'],
    datasets: [{
      data: [data?.BuscarResumo.consolidado.total_investido ?? 0, data?.BuscarResumo.consolidado.saldo ?? 0],
      backgroundColor: ['rgba(226, 107, 10, 0.8)', 'rgba(150, 54, 52, 0.8)', 'rgba(83, 118, 223, 0.8)'],
      hoverBackgroundColor: ['rgba(226, 107, 10, 1)', 'rgba(150, 54, 52, 1)', 'rgba(83, 118, 223, 1)'],
      hoverBorderColor: 'rgba(234, 236, 244, 0.05)',
      color: 'rgba(255, 255, 255, 1)',
    }],
  };

  const setor = {
    labels: data?.BuscarResumo.setor.label,
    datasets: [{
      data: data?.BuscarResumo.setor.value,
      backgroundColor: ['rgba(226, 107,  10, 0.8)', 'rgba(150, 54, 52, 0.8)', 'rgba(83, 118, 223, 0.8)',
        'rgba(102, 204, 102, 0.8)', 'rgba( 77, 230, 173, 0.8)', 'rgba(92, 198, 214, 0.8)',
        'rgba(255, 51, 119, 0.8)', 'rgba(204, 102, 102, 0.8)', 'rgba(133, 133, 173, 0.8)'],
      hoverBackgroundColor: ['rgba(226, 107,  10, 1)', 'rgba(150, 54, 52, 1)', 'rgba(83, 118, 223, 1)',
        'rgba(102, 204, 102, 1)', 'rgba( 77, 230, 173, 1)', 'rgba(92, 198, 214, 1)',
        'rgba(255, 51, 119, 1)', 'rgba(204, 102, 102, 1)', 'rgba(133, 133, 173, 1)'],
      hoverBorderColor: 'rgba(234, 236, 244, 0.05)',
      color: 'rgba(255, 255, 255, 1)',
    }],
  };

  const renderCapital = () => {
    return (
      <Row>
        <Col md='2'>
          <Card className='shadow'>
            <Card.Body className='text-success'>
              <h5>Capital Total</h5>
              <p>{formatNumber(gerenciamento.capital_total,0)}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md='2'>
          <Card className='shadow'>
            <Card.Body className='text-success'>
              <h5>Ordens Executadas</h5>
              <p>{formatNumber(gerenciamento.ordens_executadas,0)}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md='2'>
          <Card className='shadow'>
            <Card.Body className='text-success'>
              <h5>Exposição Total</h5>
              <p>{`${(gerenciamento.exposicao_total <= 0 ? 0 : gerenciamento.exposicao_total).toFixed(1)}%`}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md='2'>
          <Card className='shadow'>
          <Card.Body className={gerenciamento.resultado < 0 ? 'text-danger' : 'text-success'}>
              <h5>Resultado</h5>
              <p>{formatNumber(Math.abs(gerenciamento.resultado),0)}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md='2'>
          <Card className='shadow'>
            <Card.Body className={gerenciamento.stop_gain < 0 ? 'text-danger' : 'text-success'}>
              <h5>Stop Gain</h5>
              <p>{formatNumber(Math.abs(gerenciamento.stop_gain),0)}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md='2'>
          <Card className='shadow'>
          <Card.Body className={gerenciamento.stop_loss < 0 ? 'text-danger' : 'text-success'}>
              <h5>Stop Loss</h5>
              <p>{formatNumber(Math.abs(gerenciamento.stop_loss),0)}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  const renderPosicao = () => {
    return (
      <Row>
        <Doughnut
          data={posicao}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'left'
              },
              datalabels: {
                display(context) {
                  const value = context.dataset.data[context.dataIndex];
                  return value !== 0;
                },
                color: 'rgba(255, 255, 255, 1)',
                font: {
                  size: 14,
                },
                formatter(value, context) {
                    return Number(value.toFixed(0)).toLocaleString('en-US');
                },
              },
              tooltip: {
                enabled: false,
              },
            },
          }}
        />
      </Row>
    );
  };

  const renderSetor = () => {
    return (
      <Row>
        <Doughnut
          data={setor}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'left'
              },
              datalabels: {
                display: true,
                color: 'rgba(255, 255, 255, 1)',
                font: {
                  size: 14,
                },
                formatter(value, context) {
                    return Number(value.toFixed(1)).toLocaleString('en-US') + '%';
                },
              },
              tooltip: {
                enabled: false,
              },
            },
          }}
        />
      </Row>
    );
  };

  return (
    <Layout>
      <Container fluid>
        <Alert
          show={alert !== null}
          variant={alert?.variant}
          onClose={() => setAlert(null)}
          dismissible
          transition={false}
        >
          {alert?.message}
        </Alert>
        <Row>
          <Col md='12'>
            <Card>
              <Card.Header className={styles.header}>Gerenciamento do Capital</Card.Header>
              <Card.Body>
                {renderCapital()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        &nbsp;
        <Row>
          <Col md='6'>
            <Card>
              <Card.Header className={styles.header}>Posição Consolidada</Card.Header>
              <Card.Body>
                {renderPosicao()}
              </Card.Body>
            </Card>
          </Col>
          <Col md='6'>
            <Card>
              <Card.Header className={styles.header}>Posição por Setor</Card.Header>
              <Card.Body>
                {renderSetor()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Spinner show={loading} />
      </Container>
   </Layout>
  );
};

export default ListarResumo;
